import Cookies from "js-cookie";
import Concept from "../entities/concepts/Concept";

const apiUrl = `${process.env.REACT_APP_PAPI_URL}/concepts`;

export const fetchConcept = async (conceptCode: string): Promise<Concept> => {
    const response = await fetch(`${apiUrl}/${conceptCode}`, {
        headers: {
            Authorization: Cookies.get('Authorization') || ''
        },
        method: 'GET',
        credentials: 'same-origin',
    });

    if (!response.ok) {
        throw new Error("Failed to get concept");
    }

    return response.json();
}