import { useNavigate, useParams } from "react-router-dom";
import PanelHeader from "../../components/panel-header/PanelHeader";
import { fetchPolicy } from "../../apis/PoliciesApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useEffect, useReducer, useState } from "react";
import { Spinner, Button, Tooltip, MessageBar, MessageBarBody } from "@fluentui/react-components";
import { addCoverages, endCoverages, fetchCoverage } from "../../apis/CoveragesApi";
import ErrorBar from "../../components/error-bar/ErrorBar";
import Dictionary from "../../utils/Dictionary";
import { formatDateTimeString } from "../../utils/DateTimeUtils";
import ErrorResponse from "../../entities/response/ErrorResponse";
import { ArrowLeftRegular } from "@fluentui/react-icons";
import { fetchBundle } from "../../apis/BundlesApi";
import useModifyCoverageStyles from "./ModifyCoverageStyles";
import React from "react";
import CoverageForm, { CoverageFormData } from "../../components/coverage-form/CoverageForm";

type ModifyCoverageState = {
    isSubmitting: boolean,
    initialized: boolean,
    errors: string[]
    coverageFormData: CoverageFormData
}

const ModifyCoverage: React.FunctionComponent = () => {
    const queryClient = useQueryClient();
    const { policyId, coverageId } = useParams();
    const styles = useModifyCoverageStyles();
    const navigate = useNavigate();
    const [modifyCoverageState, setModifyCoverageState] = useState<ModifyCoverageState>({
        isSubmitting: false,
        initialized: false,
        errors: [],
        coverageFormData: { Insured: undefined, Coverages: [] }
    });
    const { mutate: addCoverage, isError } = useMutation(addCoverages,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['policies-coverages', policyId]);
                navigate('/policies/' + policyId);
            },
            onError: (error) => {
                if ((error as ErrorResponse).errors) {
                    const errorMessages = (error as ErrorResponse).errors.map(x => x.message);
                    setModifyCoverageState({ ...modifyCoverageState, isSubmitting: false, errors: errorMessages });
                } else {
                    const errorMessages = ["Unknow error occured. Please try again later or contact administrator."];
                    setModifyCoverageState({ ...modifyCoverageState, isSubmitting: false, errors: errorMessages });
                }
            }
        });

    const { mutate: endCoverage } = useMutation(endCoverages,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['policies-coverages', policyId]);
                addCoverage({ policyId: policyId!, request: modifyCoverageState.coverageFormData });
            },
            onError: (error) => {
                if ((error as ErrorResponse).errors) {
                    const errorMessages = (error as ErrorResponse).errors.map(x => x.message);
                    setModifyCoverageState({ ...modifyCoverageState, isSubmitting: false, errors: errorMessages });
                } else {
                    const errorMessages = ["Unknow error occured. Please try again later or contact administrator."];
                    setModifyCoverageState({ ...modifyCoverageState, isSubmitting: false, errors: errorMessages });
                }
            }
        });

    const { isLoading: isLoadingPolicy, data: policy, isError: policyLoadingError } = useQuery({
        queryKey: ['policy', policyId],
        queryFn: () => fetchPolicy(policyId!),
        refetchOnWindowFocus: false
    });

    const { isLoading: isLoadingCoverage, data: coverages, isError: coverageLoadingError } = useQuery({
        queryKey: ['coverages', coverageId],
        queryFn: () => fetchCoverage(policyId!, coverageId!),
        refetchOnWindowFocus: false,
    });

    const { isLoading: isLoadingBundle, data: bundle, isError: bundleLoadingError } = useQuery({
        queryKey: ['concept', policy?.concept_code],
        queryFn: () => fetchBundle(policy!.concept_code),
        enabled: policy?.concept_code !== undefined,
        refetchOnWindowFocus: false
    });

    const onSubmit = (data: CoverageFormData) => {
        setModifyCoverageState({ ...modifyCoverageState, isSubmitting: true, coverageFormData: data });
        //HTTP execution will be done in useEffect
    }

    useEffect(() => {
        if (modifyCoverageState.isSubmitting) {
            endCoverage({ policyId: policyId!, coverageGroupdId: coverageId! });
        }
    }, [modifyCoverageState.coverageFormData, modifyCoverageState.isSubmitting]);

    /// Initialize form with products from bundle
    useEffect(() => {
        if (!modifyCoverageState.initialized && !isLoadingBundle && !isLoadingCoverage && bundle && coverages) {
            let coverageFormData: CoverageFormData =
            {
                Insured: coverages.insured,
                Coverages: []
            };
            const products = bundle.products;
            products?.map((product, index) => {
                const coverageOfType = coverages.coverages.find(c => c.type === product.code);
                const factorsDictionary = {} as Dictionary<string>;
                const coverageData = coverageOfType?.current_instance.coverage_data;

                product.factors.forEach(factor => {
                    const factorDefinition = bundle?.factors.find(f => f.name === factor)!;
                    const currentFactorValue = coverageData ? coverageData[factor] : '';
                    let factorValue: any = currentFactorValue;
                    if (currentFactorValue === '') {
                        if (factorDefinition.type === 'DateTime') {
                            const date = new Date();
                            date.setHours(12);
                            date.setMinutes(0);
                            date.setSeconds(0);
                            factorValue = date;
                        } else if (factorDefinition.type === 'Money') {
                            factorValue = {
                                value: 0,
                                currency: process.env.REACT_APP_CURRENCY
                            };
                        } else if (factorDefinition.type === 'Integer') {
                            factorValue = 0;
                        }
                    } else if (factorDefinition.type === 'DateTime' && factorValue !== '') {
                        factorValue = new Date(factorValue);
                    }
                    factorsDictionary[factor] = factorValue;
                });
                if (coverageData ? coverageData["elements"] : false) {
                    factorsDictionary["elements"] = coverageData!["elements"];
                }

                coverageFormData.Coverages.push({
                    type: product.code,
                    data: factorsDictionary,
                    coverage_duration: {
                        end: coverageOfType!.end_date,
                        start: coverageOfType!.start_date
                    },
                    subject: {
                        identifier: coverageOfType!.current_instance.covered.identifier,
                        type: coverageOfType!.current_instance.covered.type
                    }
                });
            });
            setModifyCoverageState({ ...modifyCoverageState, initialized: true, coverageFormData: coverageFormData });
        }
    });

    return (
        <div>
            <PanelHeader title={`Modify coverages`} >
                <Tooltip content="Back to policy" relationship="label">
                    <Button onClick={() => navigate('/policies/' + policyId)} aria-label="Back to policy" icon={<ArrowLeftRegular />} />
                </Tooltip>
            </PanelHeader>
            <MessageBar
                layout="singleline"
                intent="info">
                <MessageBarBody>
                    The update process consists of two steps, the first is to end the previous insurance and the second is to create a new one. Because of that, it make take a moment for UI to reflect updated data.
                </MessageBarBody>
            </MessageBar>
            {isError && modifyCoverageState.errors.map((error, index) => <div key={index} className={styles.errorCard}><ErrorBar message={error} /></div>)}

            {(isLoadingBundle || isLoadingPolicy || isLoadingCoverage) && <Spinner label="Loading..." />}
            {policyLoadingError && <ErrorBar message='Something went wrong during policy load. Please refresh page or contact administrator' />}
            {coverageLoadingError && <ErrorBar message='Something went wrong during coverage load. Please refresh page or contact administrator' />}
            {bundleLoadingError && <ErrorBar message='Something went wrong during concept load. Please refresh page or contact administrator' />}

            {modifyCoverageState.initialized &&
                <CoverageForm bundle={bundle!} onSubmit={onSubmit} initialState={modifyCoverageState.coverageFormData} isSubmitting={modifyCoverageState.isSubmitting} isUpdate={true} />
            }
        </div >
    );
}

export default ModifyCoverage;