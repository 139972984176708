import { Card, CardHeader, Label, makeStyles } from "@fluentui/react-components";
import { Person } from "../../entities/Person";
import usePersonCardStyles from "./PersonCardStyles";

interface PersonCardProps {
    person: Person;
    title: string;
}

const PersonCard: React.FunctionComponent<PersonCardProps> = (props) => {
    const styles = usePersonCardStyles();

    return (
        <Card className={styles.card}>
            <CardHeader
                header={
                    <Label weight="semibold">
                        {props.title}
                    </Label>
                }
            >
            </CardHeader>
            <p className={styles.text}>
                <Label>{props.person.first_name} {props.person.last_name}</Label>
                <Label>{props.person.email_address}</Label>
                <Label>{props.person.mobile_phone_number}</Label>
            </p>
        </Card>
    );

}
export default PersonCard;