import Factor from "../entities/concepts/Factor";

const MoneyFactorName = "Money";
const DateTimeName = "DateTime";
const NumberName = "Integer";
const StringName = "String";

export function isMoney(factor: Factor): boolean {
    return factor.type == MoneyFactorName;
}

export function isDate(factor: Factor): boolean {
    return factor.type == DateTimeName;
}

export function isNumber(factor: Factor): boolean {
    return factor.type == NumberName;
}

export function isString(factor: Factor): boolean {
    return factor.type == StringName;
}