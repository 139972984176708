import { Controller, useForm } from "react-hook-form";
import { CoverageUser } from "../../entities/CoverageUser";
import { useEffect, useState } from "react";
import { Card, Dropdown, Field, Input, Spinner, Switch, Option, mergeClasses, Button } from "@fluentui/react-components";
import DateTimePicker from "../../components/date-time-picker/DateTimePicker";
import { toSentenceCase } from "../../utils/StringUtils";
import Bundle from "../../entities/concepts/Bundle";
import useCoverageFormStyles from "./CoverageFormStyles";
import ElementPicker from "../elements/ElementPicker";
import NewCoverageService from "../../entities/requests/NewCoverageService";
import { isDate, isMoney, isNumber, isString } from "../../utils/FactorsUtils";
import Element from "../../entities/concepts/Element";
import Dictionary from "../../utils/Dictionary";

export type CoverageFormData = {
    Insured: CoverageUser | undefined,
    Coverages: NewCoverageService[]
}

type CoverageFormState = {
    includeInsured: boolean,
    productElements: Dictionary<any | undefined>[],
    initialized: boolean
    displayProductElements: boolean[]
}

interface CoverageFormProps {
    bundle: Bundle
    onSubmit: (data: CoverageFormData) => void
    initialState: CoverageFormData
    isSubmitting: boolean
    isUpdate: boolean
}

const CoverageForm: React.FunctionComponent<CoverageFormProps> = (props: CoverageFormProps) => {
    const styles = useCoverageFormStyles();
    const { register, handleSubmit, setValue, getValues, control } = useForm<CoverageFormData>({
        defaultValues: props.initialState
    });
    const initialValueIncludesInsured = props.initialState.Insured !== null && props.initialState.Insured !== undefined;
    const [formState, setFormState] = useState<CoverageFormState>({
        includeInsured: initialValueIncludesInsured,
        productElements: [],
        initialized: false,
        displayProductElements: []
    });

    const onFactorChanged = (productIndex: number, factorName: string, value: any) => {
        setValue(`Coverages.${productIndex}.data.${factorName}`, value, { shouldTouch: true });
    }

    const onAttributeChanged = (productIndex: number, element: Element, value: any | null) => {
        const elementValue = value ?? undefined;
        let newProductElements = formState.productElements;
        newProductElements[productIndex][element.code] = elementValue;
        setFormState({ ...formState, productElements: newProductElements });
    };

    const products = props.bundle.products;
    useEffect(() => {
        if (!formState.initialized) {
            const elements = products!.map(product => {
                let elementDict: Dictionary<any | undefined> = {};
                const productClaimData = props.initialState.Coverages.find(x => x.type === product.code);
                const productElements = productClaimData?.data["elements"];
                product.elements.forEach(element => {
                    //Either take element from initial state of claim_data or from product definition
                    if (productElements) {
                        const elementValue = productElements[element.code];
                        elementDict[element.code] = elementValue;
                    }

                    if (elementDict[element.code] === undefined) {
                        if (element.required) {
                            var defaultAttribute = product.attributes.find(x => x.code == element.attributes[0]);
                            elementDict[element.code] = defaultAttribute?.value;
                        } else {
                            elementDict[element.code] = undefined;
                        }
                    }
                });
                return elementDict;
            });
            const displayProductElements = products!.map((product) => {
                const anyElementWithMoreThanOneAttribute = product.elements.some(element => element.attributes.length > 1);
                return anyElementWithMoreThanOneAttribute;
            });
            setFormState({ ...formState, productElements: elements, initialized: true, displayProductElements: displayProductElements });
        }
    });

    if (!formState.initialized)
        return (<></>)

    return (
        <>
            <form onSubmit={handleSubmit((data) => {
                if (!formState.includeInsured) {
                    data.Insured = undefined;
                }

                const elements = formState.productElements;
                for (let i = 0; i < data.Coverages.length; i++) {
                    data.Coverages[i].data.elements = elements[i];
                }

                props.onSubmit(data);
            })}>
                <Switch
                    label="Include insured data"
                    checked={formState.includeInsured}
                    onChange={() => {
                        setFormState(prevState => ({ ...prevState, includeInsured: !prevState.includeInsured }));
                    }}
                />
                {formState.includeInsured &&
                    <Card className={mergeClasses(styles.userDataWrapper, styles.productCard)}>
                        <div>
                            <b>Insured information</b>
                            <Field label="Identification number" required>
                                <Input defaultValue={props.initialState.Insured?.identification_number} {...register("Insured.identification_number", { required: formState.includeInsured })} />
                            </Field>
                            <Field label="First Name" required>
                                <Input defaultValue={props.initialState.Insured?.first_name} {...register("Insured.first_name", { required: formState.includeInsured })} />
                            </Field>
                            <Field label="Last name" required>
                                <Input defaultValue={props.initialState.Insured?.last_name} {...register("Insured.last_name", { required: formState.includeInsured })} />
                            </Field>
                        </div>
                        <div>
                            <b>Contact</b>
                            <Field label="Email address">
                                <Input defaultValue={props.initialState.Insured?.contact?.email_address} {...register("Insured.contact.email_address")} />
                            </Field>
                            <Field label="Mobile phone number">
                                <Input defaultValue={props.initialState.Insured?.contact?.mobile_phone_number} {...register("Insured.contact.mobile_phone_number")} />
                            </Field>
                            <Field label="Home phone number">
                                <Input defaultValue={props.initialState.Insured?.contact?.home_phone_number} {...register("Insured.contact.home_phone_number")} />
                            </Field>
                        </div>
                        <div>
                            <b>Address</b>
                            <Field label="Street">
                                <Input defaultValue={props.initialState.Insured?.address?.street} {...register("Insured.address.street")} />
                            </Field>
                            <Field label="City">
                                <Input defaultValue={props.initialState.Insured?.address?.city} {...register("Insured.address.city")} />
                            </Field>
                            <Field label="Country">
                                <Input defaultValue={props.initialState.Insured?.address?.country} {...register("Insured.address.country")} />
                            </Field>
                            <Field label="Postal code">
                                <Input defaultValue={props.initialState.Insured?.address?.postal_code} {...register("Insured.address.postal_code")} />
                            </Field>
                        </div>
                    </Card>
                }
                <div className={styles.productsWrapper}>
                    {products!.map((product, index) => {
                        const subjectType = getValues(`Coverages.${index}.subject.type`);
                        let pickedSubjectType = product.subject_types.find(x => x.code === subjectType);
                        if (!pickedSubjectType) {
                            pickedSubjectType = product.subject_types[0];
                        }

                        return (
                            <Card key={product.code} className={styles.productCard}>
                                <div className={styles.productHeaderWrapper}><b>{product.name}</b>
                                    <Switch
                                        label="Display elements"
                                        checked={formState.displayProductElements[index]}
                                        onChange={() => {
                                            let newDisplayProductElements = formState.displayProductElements;
                                            newDisplayProductElements[index] = !newDisplayProductElements[index];
                                            setFormState({ ...formState, displayProductElements: newDisplayProductElements });
                                        }}
                                    />
                                </div>
                                <Dropdown
                                    disabled={props.isUpdate}
                                    defaultValue={pickedSubjectType.name}
                                    defaultSelectedOptions={[pickedSubjectType.code]}
                                    {...register(`Coverages.${index}.subject.type`)}
                                >
                                    {product.subject_types.map(subjectType =>
                                        <Option key={subjectType.code} value={subjectType.code}>{subjectType.name}</Option>
                                    )}
                                </Dropdown>
                                <Field required label="Subject identifier">
                                    <Input disabled={props.isUpdate} defaultValue={props.initialState.Coverages[index].subject.identifier} {...register(`Coverages.${index}.subject.identifier`, { required: true })} />
                                </Field>
                                {formState.displayProductElements[index] &&
                                    <div className={styles.elementsWrapper}>
                                        {product.elements.map(element => {
                                            var attributes = product.attributes.filter(attribute => element.attributes.includes(attribute.code));
                                            const selectedAttribute = formState.productElements[index][element.code] ?? null;
                                            return (
                                                <ElementPicker
                                                    key={element.code}
                                                    element={element}
                                                    attributes={attributes}
                                                    initiallySelectedAttributeValue={selectedAttribute}
                                                    onSelected={(element, attribute) => onAttributeChanged(index, element, attribute?.value)} />
                                            )
                                        })}
                                    </div>
                                }

                                <Controller
                                    name={`Coverages.${index}.coverage_duration.start`}
                                    control={control}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <DateTimePicker
                                            required
                                            label="Coverage start"
                                            value={value}
                                            onChange={(date) => {
                                                onChange(date);
                                                setValue(`Coverages.${index}.coverage_duration.start`, date, { shouldTouch: true });
                                            }}
                                        />
                                    )}
                                />

                                <Controller
                                    name={`Coverages.${index}.coverage_duration.end`}
                                    control={control}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <DateTimePicker
                                            required
                                            label="Coverage end"
                                            value={value}
                                            onChange={(date) => {
                                                onChange(date);
                                                setValue(`Coverages.${index}.coverage_duration.end`, date, { shouldTouch: true });
                                            }}
                                        />
                                    )}
                                />

                                {product.factors.length !== 0 &&
                                    <>
                                        <b>Insurance data</b>
                                        {product.factors.map(factor => {
                                            const factorDefinition = props.bundle.factors.find(f => f.name === factor)!;
                                            const factorValue = getValues(`Coverages.${index}.data.${factorDefinition.name}`);
                                            const displayName = toSentenceCase(factorDefinition.display_name);
                                            return (
                                                <div key={factor}>
                                                    {isDate(factorDefinition) &&
                                                        <Controller
                                                            name={`Coverages.${index}.data.${factorDefinition.name}`}
                                                            control={control}
                                                            render={({ field: { onChange, value, ref } }) => (
                                                                <DateTimePicker
                                                                    required={factorDefinition.required}
                                                                    label={displayName}
                                                                    value={value}
                                                                    onChange={(date) => {
                                                                        setValue(`Coverages.${index}.data.${factorDefinition.name}`, date, { shouldTouch: true });
                                                                        onChange(date);
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    {isString(factorDefinition) &&
                                                        <Field label={displayName} required={factorDefinition.required}>
                                                            <Input
                                                                {...register(`Coverages.${index}.data.${factorDefinition.name}`)}
                                                                defaultValue={factorValue}
                                                                onChange={(e) => onFactorChanged(index, factorDefinition.name, e.target.value)}
                                                            />
                                                        </Field>
                                                    }
                                                    {isMoney(factorDefinition) &&
                                                        <Field required={factorDefinition.required} label={displayName}>
                                                            <Input
                                                                {...register(`Coverages.${index}.data.${factorDefinition.name}.Value`)}
                                                                type="number"
                                                                contentAfter={process.env.REACT_APP_CURRENCY}
                                                                defaultValue={factorValue.Value}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    onFactorChanged(index, factorDefinition.name, { value: value, currency: process.env.REACT_APP_CURRENCY });
                                                                }} />
                                                        </Field>
                                                    }
                                                    {isNumber(factorDefinition) &&
                                                        <Field required={factorDefinition.required} label={displayName}>
                                                            <Input
                                                                {...register(`Coverages.${index}.data.${factorDefinition.name}`)}
                                                                type="number"
                                                                defaultValue={factorValue}
                                                                min={0}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    onFactorChanged(index, factorDefinition.name, Number(value));
                                                                }} />
                                                        </Field>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                            </Card>
                        )
                    })}
                </div>
                <Button
                    className={styles.submitButton}
                    appearance="primary"
                    type="submit"
                    disabled={props.isSubmitting}>
                    {props.isSubmitting && <Spinner />}
                    {props.isUpdate ? "Update" : "Save"}
                </Button>
            </form >
        </ >
    );
}

export default CoverageForm;