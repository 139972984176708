import React, { useEffect } from "react";
import { PublicClientApplication, AuthenticationResult } from "@azure/msal-browser";
import { useAppDispatch, useAppSelector } from "../../store/Hooks";
import { login, logout } from "../../store/auth/Actions";
import { useNavigate } from "react-router-dom";
import { ArrowEnterRegular, ArrowExitRegular } from "@fluentui/react-icons";

interface AuthApp {
    msalApp: PublicClientApplication;
    loginRequestConfig: { scopes: string[]; };
    tokenRequestConfig: { scopes: string[]; };
    silentRequestConfig: { scopes: string[]; };
    useRedirectFlow: boolean;
}

const Authentication: React.FunctionComponent = () => {
    const authState = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const msalConfig = {
        auth: {
            clientId: process.env.REACT_APP_AUTH_CLIENT!,
            authority: process.env.REACT_APP_AUTH_AUTHORITY!,
            validateAuthority: true,
            redirectUri: window.origin,
            navigateToLoginRequestUrl: false
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
        },
    }
    const authApp: AuthApp = {
        useRedirectFlow: true,
        loginRequestConfig: {
            scopes: [process.env.REACT_APP_AUTH_SCOPE!]
        },
        tokenRequestConfig: {
            scopes: [process.env.REACT_APP_AUTH_SCOPE!]
        },
        silentRequestConfig: {
            scopes: [process.env.REACT_APP_AUTH_SCOPE!]
        },
        msalApp: new PublicClientApplication(msalConfig)
    }

    useEffect(() => {
        (async () => {
            await authApp.msalApp.initialize();
            authApp.msalApp
                .handleRedirectPromise()
                .then((resp) => {
                    if (resp != null) {
                        handleResponse(resp);
                        navigation('/dashboard');
                    }
                })
                .catch(error => {
                    console.error('Redirect login failed:', error);
                });
        })();
    }, [])

    const handleLogout = async () => {
        dispatch(logout());
        navigation('/');
    };

    const handleLogin = async () => {
        try {
            await authApp.msalApp.initialize();

            await authApp.msalApp
                .loginRedirect(authApp.loginRequestConfig);
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    const handleResponse = (response: AuthenticationResult | null) => {
        if (response !== null) {
            dispatch(login({ UserName: response.account?.name || '', Token: response.accessToken, TokenExpirationDate: response.expiresOn!.getTime() }));
        }
    }

    useEffect(() => {
        const interval = setInterval(function () {
            if (authState.isAuthenticated) {
                const currentTime = new Date().getTime();
                const currentTimePlusFiveMinutes = currentTime + 5 * 60 * 1000; // Adding 5 minutes
                if (currentTimePlusFiveMinutes >= authState.accessTokenExpirationDate!) {
                    console.log("Access token expired. Trying to silently obtain new one.")
                    refreshToken()
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [authState.isAuthenticated])

    const refreshToken = async () => {
        await authApp.msalApp.initialize();
        const currentAccounts = authApp.msalApp.getAllAccounts();
        if (currentAccounts.length > 1) {
            dispatch(logout());
            return;
        }

        if (currentAccounts.length == 0) {
            dispatch(logout());
            return;
        }

        const silentRequest: any = {
            scopes: authApp.silentRequestConfig.scopes,
            account: currentAccounts[0]
        };

        return authApp.msalApp.acquireTokenSilent(silentRequest)
            .catch(error => {
                dispatch(logout());
            }).then((result) => {
                handleResponse(result ?? null);
            });
    }

    return (
        authState.isAuthenticated ? (
            <a href="#" onClick={handleLogout}><ArrowExitRegular /> Logout</a>

        ) : (
            <a href="#" onClick={handleLogin}><ArrowEnterRegular />Login</a>
        )
    )
};

export default Authentication;