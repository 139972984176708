import { makeStyles } from "@fluentui/react-components";

const useNavbarStyles = makeStyles({
    navActive: {
        color: "#3eec64 !important",
        backgroundColor: "#1c5d39"
    },
    sidebar: {
        width: "250px",
        backgroundColor: "#173630",
        height: "100%",
        minHeight: "100vh",
        '& Ul': {
            listStyleType: "none",
            padding: "0",
        },
        '& li': {
            textAlign: "left",
            '& a': {
                padding: "16px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#00CC66",
                textDecoration: "none",
                '& svg': {
                    marginRight: "8px",
                    fontSize: "20px"
                },
                '&:hover': {
                    backgroundColor: "#1c5d39",
                    color: "#3eec64 !important"
                }
            }
        }
    },
    sidebarLogo: {
        padding: "20px",
        display: "block",
    },
});

export default useNavbarStyles;