import { Caption1, Card, CardHeader, Checkbox, Text } from "@fluentui/react-components";
import Attribute from "../../entities/concepts/Attribute";
import Element from "../../entities/concepts/Element";
import FixedAttribute from "../attributes/FixedAttribute";
import RangeAttribute from "../attributes/RangeAttribute";
import NamedAttribute from "../attributes/NamedAttribute";
import useElementPickerStyles from "./ElementPickerStyles";
import { useState } from "react";

interface ElementPickerProps {
    element: Element
    attributes: Attribute[]
    initiallySelectedAttributeValue: any | null
    onSelected: (element: Element, attribute: Attribute | null) => void
}

const ElementPicker: React.FunctionComponent<ElementPickerProps> = (props) => {
    const styles = useElementPickerStyles();
    let selectedAttribute: Attribute | null = null;
    if (props.initiallySelectedAttributeValue) {
        selectedAttribute = props.attributes.find(x => JSON.stringify(x.value) === JSON.stringify(props.initiallySelectedAttributeValue)) || props.attributes[0];
    } else {
        selectedAttribute = props.attributes[0];
    }

    const [pickerState, setPickerState] = useState({
        checkboxChecked: props.element.required,
        selectedAttribute: selectedAttribute
    });

    const checkboxClicked = () => {
        if (props.element.required)
            return;

        setPickerState({
            ...pickerState,
            checkboxChecked: !pickerState.checkboxChecked
        });
        props.onSelected(props.element, null);
    }

    const selectionChanged = (attribute: Attribute) => {
        setPickerState({
            ...pickerState,
            selectedAttribute: attribute
        })
        props.onSelected(props.element, attribute);
    }

    return (
        <>
            <Card
                onSelectionChange={checkboxClicked}
                key={props.element.code}
                floatingAction={
                    <Checkbox key={props.element.code} checked={pickerState.checkboxChecked} onClick={checkboxClicked} />
                }
            >
                <CardHeader
                    header={<Text weight="semibold">{props.element.display_name}</Text>}
                    description={
                        <Caption1>
                            {props.attributes.map(attribute => {
                                const isFixed = attribute.type === "Fixed";
                                const isRange = attribute.type === "Range";
                                const isNamed = attribute.type === "Named";
                                const selected = attribute === pickerState.selectedAttribute;
                                return (
                                    <div key={attribute.code}>
                                        {isFixed && <FixedAttribute attribute={attribute} selected={selected} onClick={selectionChanged} />}
                                        {isRange && <RangeAttribute attribute={attribute} selected={selected} onClick={selectionChanged} />}
                                        {isNamed && <NamedAttribute attribute={attribute} selected={selected} onClick={selectionChanged} />}
                                    </div>
                                )
                            })}
                        </Caption1>
                    }
                />
            </Card>
        </ >)
}

export default ElementPicker;