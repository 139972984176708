import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store, { persistor } from './store/Store';
import { PersistGate } from 'redux-persist/integration/react';
import { BrandVariants, createDarkTheme, createLightTheme, FluentProvider, Theme, webLightTheme } from '@fluentui/react-components';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const mysafetyTheme: BrandVariants = {
  10: "#020402",
  20: "#111C11",
  30: "#182E19",
  40: "#1C3C1F",
  50: "#214A25",
  60: "#25582B",
  70: "#286731",
  80: "#2C7737",
  90: "#2F863D",
  100: "#329643",
  110: "#35A74A",
  120: "#37B750",
  130: "#3AC856",
  140: "#3CD95D",
  150: "#3EEB63",
  160: "#8AF493",
};

const lightTheme: Theme = {
  ...createLightTheme(mysafetyTheme),
  fontFamilyBase: 'Open sans, sans-serif',
};

const darkTheme: Theme = {
  ...createDarkTheme(mysafetyTheme),
  fontFamilyBase: 'Open sans, sans-serif',
};

darkTheme.colorBrandForeground1 = mysafetyTheme[110]; // use brand[110] instead of brand[100]
darkTheme.colorBrandForeground2 = mysafetyTheme[120]; // use brand[120] instead of brand[110]

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <FluentProvider theme={lightTheme} className='height-100'>
              <App />
          </FluentProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode >
);