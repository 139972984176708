import React, { useEffect } from "react";
import useLandingPageStyles from "./LandingPageStyles";
import { Card, CardHeader, LargeTitle, Title2, Title3 } from "@fluentui/react-components";
import { DocumentBulletListMultipleRegular, DocumentBulletListRegular, DocumentOnePageAddRegular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/Hooks";

const LandingPage: React.FunctionComponent = () => {
    const styles = useLandingPageStyles();
    const navigate = useNavigate();
    const authState = useAppSelector(state => state.auth);

    useEffect(() => {
        if (authState.isAuthenticated) {
            navigate('/dashboard');
        }
    }, [navigate]);

    return (
        <>
            <div className={styles.landingPageLogo}>
                <LargeTitle>Welcome to Mysafety Partner Portal</LargeTitle>
            </div>

            <Title2 className={styles.landingPageOptionsHeader}>
                Here you can
            </Title2>

            <div className={styles.landingPageOptions}>
                <Card className={styles.optionCard} >
                    <CardHeader className={styles.optionCardHeader}
                        header={
                            <DocumentBulletListRegular />
                        }
                    >
                    </CardHeader>
                    <p>
                        <Title3>Manage your agreements</Title3>
                    </p>
                </Card>
                <Card className={styles.optionCard}>
                    <CardHeader className={styles.optionCardHeader}
                        header={
                            <DocumentBulletListMultipleRegular />
                        }
                    >
                    </CardHeader>
                    <p>
                        <Title3>Manage your policies</Title3>
                    </p>
                </Card>
                <Card className={styles.optionCard}>
                    <CardHeader className={styles.optionCardHeader}
                        header={
                            <DocumentOnePageAddRegular />
                        }
                    >
                    </CardHeader>
                    <p>
                        <Title3>Add new coverages</Title3>
                    </p>
                </Card>
            </div>
        </>
    );
}

export default LandingPage;