import { makeStyles } from "@fluentui/react-components";

const useDateTimePickerStyles = makeStyles({
    root: {
        display: "grid",
        columnGap: "10px",
        gridTemplateColumns: "1fr 1fr",
    },
});


export default useDateTimePickerStyles;