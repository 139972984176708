import Cookies from "js-cookie";
import PaginatedModel from "../entities/PaginatedModel";
import { PaginationRequest } from "../entities/requests/PaginationRequest";
import { CoverageGroup } from "../entities/CoverageGroup";
import NewCoveragesRequest from "../entities/requests/NewCoverages/NewCoverageRequest";

const apiUrl = `${process.env.REACT_APP_PAPI_URL}/policies`;

export const fetchCoverages = async (policyId: string, pagination: PaginationRequest, filters: { objectIdentifier: string, active: boolean | undefined }): Promise<PaginatedModel<CoverageGroup>> => {
    let url = `${apiUrl}/${policyId}/coverage-groups?$skip=${pagination.skip}&$take=${pagination.take}`;

    if (filters.objectIdentifier) {
        url += `&coverageObjectIdentifier=${filters.objectIdentifier}`;
    }

    if (filters.active != undefined) {
        url += `&active=${filters.active}`;
    }

    const response = await fetch(url, {
        headers: {
            Authorization: Cookies.get('Authorization') || ''
        },
        method: 'GET',
        credentials: 'same-origin',
    });

    if (!response.ok) {
        throw new Error("Failed to get coverages");
    }

    return response.json();
}

export const fetchCoverage = async (policyId: string, coverageGroupId: string): Promise<CoverageGroup> => {
    let url = `${apiUrl}/${policyId}/coverage-groups/${coverageGroupId}`;

    const response = await fetch(url, {
        headers: {
            Authorization: Cookies.get('Authorization') || ''
        },
        method: 'GET',
        credentials: 'same-origin',
    });

    if (!response.ok) {
        throw new Error("Failed to get coverage");
    }

    return response.json();
}

export const endCoverages = async (props: { policyId: string, coverageGroupdId: string }) => {
    const response = await fetch(`${apiUrl}/${props.policyId}/coverage-groups/${props.coverageGroupdId}`, {
        headers: {
            Authorization: Cookies.get('Authorization') || ''
        },
        method: 'DELETE',
        credentials: 'same-origin',
    });

    if (!response.ok) {
        const errorResponse = await response.json();
        if (response.status === 400) {
            throw errorResponse;
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
}

export const addCoverages = async (props: { policyId: string, request: NewCoveragesRequest }) => {
    const response = await fetch(`${apiUrl}/${props.policyId}/coverage-groups`, {
        body: JSON.stringify(props.request),
        headers: {
            Authorization: Cookies.get('Authorization') || '',
            'Content-Type': 'application/json'
        },
        method: 'POST',
        credentials: 'same-origin',
    });

    if (!response.ok) {
        const errorResponse = await response.json();
        if (response.status === 400) {
            throw errorResponse;
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
}

