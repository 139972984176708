import { makeStyles } from "@fluentui/react-components";

const useAgreementDetailsStyles = makeStyles({
    headerWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "16px",
    },
    agreementDetailsCard: {
        flex: 1,
        maxWidth: "100%",
        height: "fit-content",
    },
    agreementDetailsText: {
        margin: "0",
        display: "flex",
        flexDirection: "column",
    }, 
    iconButton: {
        margin: "0 4px",
        width: "32px",
        minWidth: "32px",
        minHeight: "32px",
        height: "32px",
        padding: "4px",
    },
    icon: {
        width: "16px",
        height: "16px",
    },
})

export default useAgreementDetailsStyles;