import { Divider, Title2, Title3 } from "@fluentui/react-components";
import React from "react";
import "./PanelHeader.css";

interface PanelProps {
    title: string;
    type?: string;
    children?: JSX.Element;
}

const PanelHeader: React.FunctionComponent<PanelProps> = ({ title, type = "primary", children = null }) => {

    const isPrimary = type === "primary";
    const panelClass = isPrimary ? "panel-wrapper panel-primary" : "panel-wrapper panel-secondary";

    return (
        <div className={panelClass}>
            <div className="panel-header">
                {isPrimary && <Title2>{title}</Title2>}
                {!isPrimary && <Title3>{title}</Title3>}
                {children}
            </div>
            <Divider />
        </div>)
};

export default PanelHeader;