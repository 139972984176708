
import { makeStyles } from "@fluentui/react-components";

const usePolicyFormStyles = makeStyles({
    productCard: {
        margin: "10px",
    },
    productsWrapper: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
    },
    userDataWrapper: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
    },
    errorCard: {
        marginBottom: "4px",
        paddingLeft: "8px",
        paddingRight: "8px",
    },
    submitButton: {
        marginLeft: "10px",
    },
    productHeaderWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    elementsWrapper: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "8px",
    }
});
export default usePolicyFormStyles;