import { makeStyles } from "@fluentui/react-components";

const useAgreementListStyles = makeStyles({
    agreementListWrapper: {
        display: "flex",
        flexDirection: "column",
        height: "100%"
    },
    tableWrapper: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "16px",
        justifyContent: "space-between",
    },
})

export default useAgreementListStyles;