import { createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, TableCellLayout, TableColumnDefinition } from "@fluentui/react-components";
import { CoverageGroup } from "../../entities/CoverageGroup";
import { CoverageService } from "../../entities/CoverageService";
import { CoverageInstance } from "../../entities/CoverageInstance";
import { toSentenceCase } from "../../utils/StringUtils";
import Money from "../../entities/factors/Money";
import { Console, debug } from "console";

interface CoverageGroupListProps {
    coverageGroup: CoverageGroup;
}

const CoverageInstancesList: React.FunctionComponent<CoverageGroupListProps> = (props: CoverageGroupListProps) => {
    const data = props.coverageGroup.coverages;
    const coverageInstances = props.coverageGroup?.coverages
        .map((coverage) => coverage?.coverage_instances)
        ?.flatMap((instances) => instances)
        ?.map((instance) => instance?.coverage_data)
        .filter(instance => instance !== null && instance !== undefined)
        ?.flatMap(Object.keys) ?? [];

    const factorNames = Array.from(
        new Set(coverageInstances));

    const columns: TableColumnDefinition<CoverageService>[] = [
        createTableColumn<CoverageService>({
            columnId: "Insurance",
            renderHeaderCell: () => {
                return "Insurance type";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.name}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<CoverageService>({
            columnId: "CoveredObject",
            renderHeaderCell: () => {
                return "Covered object";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.current_instance.covered.identifier}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<CoverageService>({
            columnId: "CoveredObjectType",
            renderHeaderCell: () => {
                return "Covered object type";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.current_instance.covered.type}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<CoverageService>({
            columnId: "CoveragePeriod",
            renderHeaderCell: () => {
                return "Coverage period";
            },
            renderCell: (item) => {
                const coverageEndDateString = item.current_instance.end_date ? new Date(item.current_instance.end_date).toLocaleDateString() : 'active';
                return (
                    <TableCellLayout>
                        {new Date(item.current_instance.start_date).toLocaleDateString()} - {coverageEndDateString}
                    </TableCellLayout>
                );
            },
        }),
    ];

    factorNames.forEach((factorName) => {
        if (factorName.toLowerCase() == "elements")
            return;

        columns.push(
            createTableColumn<CoverageService>({
                columnId: factorName,
                renderHeaderCell: () => {
                    return toSentenceCase(factorName);
                },
                renderCell: (item) => {
                    const coverageData = item.current_instance.coverage_data;
                    let factorValue = coverageData ? coverageData[factorName] : null;
                    if (factorValue === null || factorValue === undefined) {
                        return (
                            <TableCellLayout>
                                <span>-</span>
                            </TableCellLayout>
                        );
                    }
                    if (typeof factorValue === 'object') {
                        const money = factorValue as Money;
                        if (money) {
                            factorValue = `${money.Value} ${money.Currency}`;
                        }
                        //This is terrible, but a workaround for now
                    } else if (factorName.toLowerCase().includes('date')) {
                        factorValue = new Date(factorValue).toLocaleString();
                    }
                    return (
                        <TableCellLayout>
                            <span>{factorValue}</span>
                        </TableCellLayout>
                    );
                },
            })
        );
    });

    return (
        <DataGrid
            items={data}
            columns={columns}
            focusMode="composite"
            style={{ minWidth: "550px", padding: "0 0 0 20px" }}
        >
            <DataGridHeader>
                <DataGridRow >
                    {({ renderHeaderCell }) => (
                        <DataGridHeaderCell><b>{renderHeaderCell()}</b></DataGridHeaderCell>
                    )}
                </DataGridRow>
            </DataGridHeader>
            <DataGridBody<CoverageInstance>>
                {({ item, rowId }) => (
                    <DataGridRow<CoverageInstance>
                        key={rowId}
                        className='cursor-pointer'
                    >
                        {({ renderCell }) => (
                            <DataGridCell>{renderCell(item)}</DataGridCell>
                        )}
                    </DataGridRow>
                )}
            </DataGridBody>
        </DataGrid>
    )
}

export default CoverageInstancesList;