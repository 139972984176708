import PaginatedModel from "../../entities/PaginatedModel";
import { Policy } from "../../entities/Policy";
import { Button, createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, TableCellLayout, TableColumnDefinition, Tooltip } from '@fluentui/react-components';
import ActionButton from "../../entities/models/ActionButton";
import usePolicyListStyles from "./PolicyListStyles";

type PoliciesListProps = {
    items: PaginatedModel<Policy>;
    actions: ActionButton<Policy>[];
}

const PoliciesList: React.FunctionComponent<PoliciesListProps> = (props) => {
    const styles = usePolicyListStyles();

    const columns: TableColumnDefinition<Policy>[] = [
        // createTableColumn<Policy>({
        //     columnId: "Id",
        //     renderHeaderCell: () => {
        //         return "Id";
        //     },
        //     renderCell: (item) => {
        //         return (
        //             <TableCellLayout >
        //                 {item.policy_id}
        //             </TableCellLayout>
        //         );
        //     },
        // }),
        createTableColumn<Policy>({
            columnId: "Concept",
            renderHeaderCell: () => {
                return "Concept";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.concept_name}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Policy>({
            columnId: "Period",
            renderHeaderCell: () => {
                return "Period";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {new Date(item.period.start_date).toLocaleDateString()} - {new Date(item.period.end_date).toLocaleDateString()}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Policy>({
            columnId: "CancellationDate",
            renderHeaderCell: () => {
                return "Cancellation date";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {(item.cancellation_date == null ? "-" : new Date(item.cancellation_date).toLocaleDateString())}
                    </TableCellLayout>
                );
            },
        }),
        // createTableColumn<Policy>({
        //     columnId: "PolicyType",
        //     renderHeaderCell: () => {
        //         return "Type";
        //     },
        //     renderCell: (item) => {
        //         return (
        //             <TableCellLayout >
        //                 {item.policy_type}
        //             </TableCellLayout>
        //         );
        //     },
        // }),
        createTableColumn<Policy>({
            columnId: "Status",
            renderHeaderCell: () => {
                return "Status";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.policy_status}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Policy>({
            columnId: "Holder",
            renderHeaderCell: () => {
                return "Holder";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.holder == null ? "-" : (item.holder.first_name + " " + item.holder.last_name)}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Policy>({
            columnId: "Bussines unit",
            renderHeaderCell: () => {
                return "Bussiness unit";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.business_unit == null ? "-" : item.business_unit}
                    </TableCellLayout>
                );
            },
        })

    ];

    if (props.actions.length > 0) {
        columns.push(
            createTableColumn<Policy>({
                columnId: "actions",
                renderHeaderCell: () => {
                    return "Actions";
                },
                renderCell: (item) => {
                    return (
                        <TableCellLayout >
                            {props.actions.map((action, index) => (
                                <Tooltip key={index} content={action.toolTip} relationship="label">
                                    <Button onClick={() => action.onClick(item)} aria-label={action.toolTip} icon={action.icon} className={styles.iconButton} />
                                </Tooltip>
                            ))}
                        </TableCellLayout>
                    );
                },
            }))
    }

    return (
        <DataGrid
            items={props!.items.items}
            columns={columns}
            focusMode="composite"
            style={{ minWidth: "550px" }}
        >
            <DataGridHeader>
                <DataGridRow >
                    {({ renderHeaderCell }) => (
                        <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                    )}
                </DataGridRow>
            </DataGridHeader>
            <DataGridBody<Policy>>
                {({ item, rowId }) => (
                    <DataGridRow<Policy>
                        key={rowId}
                        className='cursor-pointer'
                    >
                        {({ renderCell }) => (
                            <DataGridCell>{renderCell(item)}</DataGridCell>
                        )}
                    </DataGridRow>
                )}
            </DataGridBody>
        </DataGrid>
    );

}
export default PoliciesList;