import { makeStyles } from "@fluentui/react-components";

const usePolicyCoverageListStyles = makeStyles({
    actionButton: {
        margin: "2px",
    },
    iconButton: {
        margin: "0 4px",
        width: "32px",
        minWidth: "32px",
        minHeight: "32px",
        height: "32px",
        padding: "4px",
    },
    icon: {
        width: "16px",
        height: "16px",
    },
    searchBarWrapper: {
        marginTop: "8px",
        marginBottom: "8px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    searchBar: {
        width: "100%",
    }
})

export default usePolicyCoverageListStyles;