import { Radio } from "@fluentui/react-components";
import Attribute from "../../entities/concepts/Attribute";

interface FixedAttributeModel {
    value: string;
    unit: string;
}

interface FixedAttributeProps {
    attribute: Attribute
    selected: boolean
    onClick: (attribute: Attribute) => void
}

const FixedAttribute: React.FunctionComponent<FixedAttributeProps> = (props) => {
    const fixedAttribute = props.attribute.value as FixedAttributeModel;

    return (
        <Radio
            value={props.attribute.code}
            label={`${fixedAttribute.value} ${fixedAttribute.unit}`}
            checked={props.selected}
            onClick={() => props.onClick(props.attribute)} />
    )
}

export default FixedAttribute;