import { makeStyles } from "@fluentui/react-components";

const usePersonCardStyles = makeStyles({
    card: {
        flex: 1,
        maxWidth: "100%",
        height: "fit-content",
    },
    text: {
        margin: "0",
        display: "flex",
        flexDirection: "column",
    },
});
export default usePersonCardStyles;