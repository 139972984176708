import { makeStyles } from "@fluentui/react-components";

const useDashboardStyles = makeStyles({

    dashboardOptions: {
        display: "flex",
        gap: "20px",
    },
    optionCard: {
        cursor: "pointer",
        display: "flex",
        height: "fit-content",
        minWidth: "200px",
        alignItems: "center",
        justifyContent: "center",
    },
    optionCardHeader: {
        fontSize: "40px",
    }
});
export default useDashboardStyles;