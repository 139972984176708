import { Radio } from "@fluentui/react-components";
import Attribute from "../../entities/concepts/Attribute";

interface NamedAttributeProps {
    attribute: Attribute
    selected: boolean
    onClick: (attribute: Attribute) => void
}

const NamedAttribute: React.FunctionComponent<NamedAttributeProps> = (props) => {
    return (
        <Radio
            value={props.attribute.code}
            label={props.attribute.display_name}
            checked={props.selected}
            onClick={() => props.onClick(props.attribute)} />
    )
}

export default NamedAttribute;