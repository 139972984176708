import './styles/App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import AgreementsList from './views/agreements-list/AgreementsList';
import LandingPage from './views/landing-page/LandingPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import Navbar from './components/nav/Navbar';
import ProtectedRoute from './components/routing/ProtectedRoute';
import AgreementDetails from './views/agreement-details/AgreementDetails';
import Dashboard from './views/dashboard/Dashboard';
import PoliciesListView from './views/policies-list/PoliciesListView';
import PolicyDetails from './views/policy-details/PolicyDetails';
import AddCoverage from './views/add-coverage/AddCoverage';
import ModifyCoverage from './views/modify-coverage/ModifyCoverage';
import Footer from './components/footer/Footer';
import AddPolicy from './views/add-policy/AddPolicy';
import ErrorBoundary from './views/error/ErrorPage';
import NotFound from './views/not-found/NotFound';

function App() {
  const queryClient = new QueryClient();
  const location = useLocation();

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Navbar />
        <div className={location.pathname === "/" ? "content" : "content content-padding"}>
          <ErrorBoundary>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
              <Route path="/agreements/:agreementId" element={<ProtectedRoute><AgreementDetails /></ProtectedRoute>} />
              <Route path="/agreements/:agreementId/policies/new" element={<ProtectedRoute><AddPolicy /></ProtectedRoute>} />
              <Route path="/agreements" element={<ProtectedRoute><AgreementsList /></ProtectedRoute>} />
              <Route path="/policies" element={<ProtectedRoute><PoliciesListView /></ProtectedRoute>} />
              <Route path="/policies/:policyId" element={<ProtectedRoute><PolicyDetails /></ProtectedRoute>} />
              <Route path="/policies/:policyId/coverages/new" element={<ProtectedRoute><AddCoverage /></ProtectedRoute>} />
              <Route path="/policies/:policyId/coverages/:coverageId/modify" element={<ProtectedRoute><ModifyCoverage /></ProtectedRoute>} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </ErrorBoundary>
          <Footer />
        </div>
      </QueryClientProvider>
    </div>
  );
}

export default App;
