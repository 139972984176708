import { makeStyles } from "@fluentui/react-components";

const useFooterStyles = makeStyles({
    footer: {
        marginTop: "auto",
        textDecoration: "none",
        color: "rgb(173630)",
        paddingTop: "4px",
    },
    footerPadding: {
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    footerSpan:{
        marginTop: "4px",
        marginBottom: "4px",
    }
});
export default useFooterStyles;