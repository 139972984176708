import { useNavigate, useParams } from "react-router-dom";
import PanelHeader from "../../components/panel-header/PanelHeader";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import { Spinner, Button, Tooltip, MessageBar, MessageBarBody } from "@fluentui/react-components";
import ErrorBar from "../../components/error-bar/ErrorBar";
import { formatDateTimeString } from "../../utils/DateTimeUtils";
import ErrorResponse from "../../entities/response/ErrorResponse";
import { ArrowLeftRegular } from "@fluentui/react-icons";
import useAddPolicyStyles from "./AddPolicyStyles";
import Dictionary from "../../utils/Dictionary";
import { addPolicyUnderAgreement, fetchAgreement } from "../../apis/AgreementsApi";
import { fetchConcept } from "../../apis/ConceptApi";
import PolicyForm, { PolicyFormData } from "../../components/policy-form/PolicyForm";
import { isDate, isMoney } from "../../utils/FactorsUtils";

type AddPolicyState = {
    isSubmitting: boolean,
    initialized: boolean,
    errors: string[]
    policyFormData: PolicyFormData
}

const AddPolicy: React.FunctionComponent = () => {
    const queryClient = useQueryClient();
    const styles = useAddPolicyStyles();
    const { agreementId } = useParams();
    const navigate = useNavigate();
    const [formState, setFormState] = useState<AddPolicyState>({
        isSubmitting: false,
        initialized: false,
        errors: [],
        policyFormData: { holder: undefined, coverage_groups: [], concept_code: '', period: { start_date: new Date(), end_date: new Date() } }
    });
    const { mutate, isError } = useMutation(addPolicyUnderAgreement,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['agreement-policies', agreementId]);
                navigate('/agreements/' + agreementId);
            },
            onError: (error) => {
                if ((error as ErrorResponse).errors) {
                    const errorMessages = (error as ErrorResponse).errors.map(x => x.message);
                    setFormState({ ...formState, isSubmitting: false, errors: errorMessages });
                } else {
                    const errorMessages = ["Unknow error occured. Please try again later or contact administrator."];
                    setFormState({ ...formState, isSubmitting: false, errors: errorMessages });
                }
            }
        });

    const { isLoading: isLoadingAgreement, data: agreement, isError: agreementLoadingError } = useQuery({
        queryKey: ['agreement', agreementId],
        queryFn: () => fetchAgreement(agreementId!),
        refetchOnWindowFocus: false
    });

    const { isLoading: isLoadingConcept, data: concept, isError: conceptLoadingError } = useQuery({
        queryKey: ['concept', agreement?.allowed_concepts[0]],
        queryFn: () => fetchConcept(agreement!.allowed_concepts[0]),
        enabled: agreement !== undefined,
        refetchOnWindowFocus: false
    });

    const onSubmit = (data: PolicyFormData) => {
        setFormState({ ...formState, isSubmitting: true });
        mutate({
            agreementId: agreementId!,
            request: {
                concept_code: data.concept_code,
                holder: data.holder,
                period: data.period,
                coverage_groups: data.coverage_groups
            }
        });
    }

    /// Initialize form with products from concept
    useEffect(() => {
        if (!formState.initialized && !isLoadingConcept && concept) {
            const bundle = concept.bundles[0];
            let policyFormData: PolicyFormData =
            {
                holder: undefined,
                coverage_groups: [],
                concept_code: concept.code,
                period: {
                    start_date: new Date(),
                    end_date: new Date()
                }
            };
            const products = bundle.products;
            products?.map((product, index) => {
                const factorsDictionary = {} as Dictionary<string>;
                product.factors.forEach(factor => {
                    const factorDefinition = bundle?.factors.find(f => f.name === factor)!;
                    let factorValue: any = '';

                    if (isDate(factorDefinition)) {
                        const date = new Date();
                        date.setHours(12);
                        date.setMinutes(0);
                        date.setSeconds(0);
                        factorValue = formatDateTimeString(date);
                    } else if (isMoney(factorDefinition)) {
                        factorValue = {
                            value: 0,
                            currency: process.env.REACT_APP_CURRENCY
                        };
                    }
                    factorsDictionary[factor] = factorValue;
                });

                if (policyFormData.coverage_groups.length === 0) {
                    policyFormData.coverage_groups.push({
                        Insured: undefined,
                        Coverages: []
                    });
                }
                const endDate = new Date();
                endDate.setMonth(endDate.getMonth() + 12);

                policyFormData.coverage_groups[0].Coverages.push({
                    type: product.code,
                    data: factorsDictionary,
                    coverage_duration: {
                        end: endDate,
                        start: new Date()
                    },
                    subject: {
                        identifier: '',
                        type: product.subject_types[0].code
                    }
                });
            });
            setFormState({ ...formState, initialized: true, policyFormData: policyFormData });
        }
    });

    return (
        <div>
            <PanelHeader title={`Agreement #${agreementId} - Add policy`} >
                <Tooltip content="Back to agreement" relationship="label">
                    <Button onClick={() => navigate('/agreements/' + agreementId)} aria-label="Back to agreement" icon={<ArrowLeftRegular />} />
                </Tooltip>
            </PanelHeader>
            <MessageBar
                layout="singleline"
                intent="info">
                <MessageBarBody>
                    Process of creation of new policies is executed asynchronously, because of that UI may not reflect created data instantly.
                </MessageBarBody>
            </MessageBar>
            {isError && formState.errors.map((error, index) => <div key={index} className={styles.errorCard}><ErrorBar message={error} /></div>)}

            {isLoadingConcept || isLoadingAgreement && <Spinner label="Loading..." />}
            {agreementLoadingError && <ErrorBar message='Something went wrong during agreement load. Please refresh page or contact administrator' />}
            {conceptLoadingError && <ErrorBar message='Something went wrong during concept load. Please refresh page or contact administrator' />}

            {formState.initialized &&
                <PolicyForm bundle={concept!.bundles[0]} onSubmit={onSubmit} initialState={formState.policyFormData} isSubmitting={formState.isSubmitting} isUpdate={false} />
            }
        </div >
    );
}

export default AddPolicy;