import { Field } from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { formatDateToTimeString, TimePicker, TimeSelectionData, TimeSelectionEvents } from "@fluentui/react-timepicker-compat";
import useDateTimePickerStyles from "./DateTimePickerStyles";
import { formatDateString } from "../../utils/DateTimeUtils";

interface DateTimePickerProps {
    value: Date
    onChange: (date: Date) => void
    label: string
    required: boolean
}

const DateTimePicker: React.FunctionComponent<DateTimePickerProps> = (props: DateTimePickerProps) => {
    const styles = useDateTimePickerStyles();
    const pickedDate = new Date(props.value);

    const onDateSelected = (date: Date | null | undefined) => {
        if (date) {
            pickedDate.setDate(date.getDate());
            pickedDate.setMonth(date.getMonth());
            pickedDate.setFullYear(date.getFullYear());
            props.onChange(pickedDate);
        }
    };

    const onTimeSelected = (event: TimeSelectionEvents, data: TimeSelectionData) => {
        if (data.selectedTime) {
            pickedDate.setHours(data.selectedTime.getHours());
            pickedDate.setMinutes(data.selectedTime.getMinutes());
            props.onChange(pickedDate);
        }
    };
    const pickedTime = formatDateToTimeString(pickedDate, { hourCycle: 'h23' });

    return (
        <Field label={props.label} required={props.required}>
            <div className={styles.root}>
                <DatePicker
                    placeholder="Select a date..."
                    value={pickedDate}
                    onSelectDate={onDateSelected}
                    formatDate={(date) => !date ? "" : formatDateString(date!)}
                />
                <TimePicker
                    hourCycle="h23"
                    increment={15}
                    inlinePopup={true}
                    clearable={false}
                    dateAnchor={pickedDate}
                    selectedTime={pickedDate}
                    value={pickedTime}
                    onTimeChange={onTimeSelected}
                />
            </div >
        </Field>

    );
};

export default DateTimePicker;