import { useNavigate, useParams } from "react-router-dom";
import PanelHeader from "../../components/panel-header/PanelHeader";
import AgreementPoliciesList from "./AgreementPoliciesList";
import { useQuery } from "react-query";
import { fetchAgreement } from "../../apis/AgreementsApi";
import { Button, Card, CardHeader, Label, Spinner, Tooltip } from "@fluentui/react-components";
import ErrorBar from "../../components/error-bar/ErrorBar";
import PersonCard from "../../components/person-card/PersonCard";
import { capitalize } from "../../utils/StringUtils";
import useAgreementDetailsStyles from "./AgreementDetailsStyles";
import { AddRegular, ArrowLeftRegular } from "@fluentui/react-icons";

const AgreementDetails: React.FunctionComponent = () => {
    const { agreementId } = useParams();
    const styles = useAgreementDetailsStyles();
    const navigate = useNavigate();

    const { isLoading, data, isError } = useQuery({
        queryKey: ['agreement', agreementId],
        queryFn: () => fetchAgreement(agreementId!),
        refetchOnWindowFocus: false
    });

    const onNewPolicyButtonClicked = () => {
        navigate(`/agreements/${agreementId}/policies/new`);
    }

    return (
        <div>
            <PanelHeader title={`Agreement #${agreementId}`} >
                <Tooltip content="Back to list" relationship="label">
                    <Button onClick={() => navigate('/agreements')} aria-label="Back to list" icon={<ArrowLeftRegular />} />
                </Tooltip>
            </PanelHeader>

            {isLoading && <Spinner />}
            {isError && <ErrorBar message='Something went wrong during policy agreement. Please refresh page or contact administrator' />}

            {!isLoading && !isError &&
                <span>
                    <div className={styles.headerWrapper}>
                        <Card className={styles.agreementDetailsCard}>
                            <CardHeader
                                header={
                                    <Label weight="semibold">
                                        Agreement details
                                    </Label>
                                }
                            >
                            </CardHeader>
                            <p className={styles.agreementDetailsText}>
                                <Label>{new Date(data!.start_date).toLocaleDateString()} -  {new Date(data!.end_date).toLocaleDateString()}</Label >
                                <Label>{capitalize(data!.type.replaceAll("_", " "))}</Label>
                                <Label>{data?.allowed_concepts}</Label>
                            </p>
                        </Card>
                        <PersonCard person={data!.administrator} title="Administrator" />
                        <PersonCard person={data!.representative} title="Representative" />
                    </div>

                    <PanelHeader title={`Policies`} type="secondary" >
                        <Tooltip content="Add policy" relationship="label">
                            <Button appearance="secondary" className={styles.iconButton} onClick={onNewPolicyButtonClicked}><AddRegular className={styles.icon} /></Button>
                        </Tooltip>
                    </PanelHeader>
                    <AgreementPoliciesList />
                </span>
            }
        </div>)
};

export default AgreementDetails;