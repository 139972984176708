import { useQuery } from "react-query";
import { fetchPolicies } from "../../apis/PoliciesApi";
import { Card, Spinner } from "@fluentui/react-components";
import PanelHeader from "../../components/panel-header/PanelHeader";
import ErrorBar from "../../components/error-bar/ErrorBar";
import PoliciesList from "../../components/policies-list/PoliciesList";
import { useState } from "react";
import Paginator from "../../components/pagination/Pagination";
import usePoliciesListViewStyles from "./PoliciesListViewStyles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Policy } from "../../entities/Policy";
import { AddRegular, DocumentBulletListMultipleRegular } from "@fluentui/react-icons";
import { getSkippedItems } from "../../utils/PaginationHelper";

const PoliciesListView: React.FunctionComponent = () => {
    const pageSize = 22;
    const navigate = useNavigate();
    const [paginationParams, setPagination] = useSearchParams({ page: '1' });
    const currentPage = parseInt(paginationParams.get('page') || '1');
    const [skipListItems, setSkipListItems] = useState(getSkippedItems(currentPage, pageSize));
    const styles = usePoliciesListViewStyles();

    const { isLoading, data, isError } = useQuery({
        queryKey: ['policies', skipListItems],
        queryFn: () => fetchPolicies({ skip: skipListItems, take: pageSize }),
        refetchOnWindowFocus: false
    });

    const onPaginationClicked = (pageNumber: number) => {
        setSkipListItems(getSkippedItems(pageNumber, pageSize));
        setPagination({ page: pageNumber.toString() });
    }

    const handleRowClick = (policy: Policy) => {
        navigate('/policies/' + policy.policy_id);
    }

    const handleNewCoverageClick = (policy: Policy) => {
        navigate('/policies/' + policy.policy_id + '/coverages/new');
    }

    return (
        <div className={styles.policiesListWrapper}>
            <PanelHeader title={`Policies`} />
            {isLoading && <Spinner />}
            {isError && <ErrorBar message='Something went wrong during policies load. Please refresh page or contact administrator' />}
            {!isLoading && !isError &&

                <Card className={styles.tableWrapper}>
                    <PoliciesList items={data!} actions={[{
                        columnId: "Navigate",
                        toolTip: "Policy details",
                        onClick: (policy) => handleRowClick(policy),
                        icon: <DocumentBulletListMultipleRegular />,
                    },
                    {
                        columnId: "AddCoverage",
                        toolTip: "Add coverage",
                        onClick: (policy) => handleNewCoverageClick(policy),
                        icon: <AddRegular />,
                    }]} />
                    <Paginator pagination={data!.pagination} onButtonClicked={pageNumber => onPaginationClicked(pageNumber)} />
                </Card>
            }
        </div>)
};

export default PoliciesListView;