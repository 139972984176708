import Cookies from "js-cookie";
import Agreement from "../entities/Agreement";
import PaginatedModel from "../entities/PaginatedModel";
import { Policy } from "../entities/Policy";
import { PaginationRequest } from "../entities/requests/PaginationRequest";
import NewPolicyRequest from "../entities/requests/NewPolicy/NewPolicyRequest";

const apiUrl = `${process.env.REACT_APP_PAPI_URL}/agreements`;

export const fetchAgreements = async (pagination: PaginationRequest): Promise<PaginatedModel<Agreement>> => {
    const response = await fetch(`${apiUrl}?$skip=${pagination.skip}&$take=${pagination.take}`, {
        headers: {
            Authorization: Cookies.get('Authorization') || ''
        },
        method: 'GET',
        credentials: 'same-origin',
    });

    if (!response.ok) {
        throw new Error("Failed to get agreements");
    }

    return response.json();
}

export const fetchAgreement = async (agreementId: string): Promise<Agreement> => {
    const response = await fetch(`${apiUrl}/${agreementId}`, {
        headers: {
            Authorization: Cookies.get('Authorization') || ''
        },
        method: 'GET',
        credentials: 'same-origin',
    });

    if (!response.ok) {
        throw new Error("Failed to get agreement");
    }

    return response.json();
}

export const fetchAgreementPolicies = async (agreementId: string, pagination: PaginationRequest): Promise<PaginatedModel<Policy>> => {
    const response = await fetch(`${apiUrl}/${agreementId}/policies?$skip=${pagination.skip}&$take=${pagination.take}`, {
        headers: {
            Authorization: Cookies.get('Authorization') || ''
        },
        method: 'GET',
        credentials: 'same-origin',
    });

    if (!response.ok) {
        throw new Error("Failed to get agreement policies");
    }

    return response.json();
}

export const addPolicyUnderAgreement = async (props: { agreementId: string, request: NewPolicyRequest }) => {
    const response = await fetch(`${apiUrl}/${props.agreementId}/policies`, {
        headers: {
            Authorization: Cookies.get('Authorization') || '',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify([props.request]),
        method: 'POST',
        credentials: 'same-origin',
    });

    if (!response.ok) {
        const errorResponse = await response.json();
        if (response.status === 400) {
            throw errorResponse;
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
}
