import { useMutation, useQuery, useQueryClient } from "react-query";
import { endCoverages, fetchCoverages } from "../../apis/CoveragesApi";
import { Button, Card, Checkbox, createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Input, MessageBar, MessageBarBody, Spinner, TableCellLayout, TableColumnDefinition, TableRowId, Tooltip } from "@fluentui/react-components";
import ErrorBar from "../../components/error-bar/ErrorBar";
import { CoverageGroup } from "../../entities/CoverageGroup";
import CoverageInstancesList from "./CoverageInstancesList";
import { useState } from "react";
import { AddRegular, DocumentBulletListMultipleRegular, DocumentDismissRegular, DocumentEditRegular, FilterDismissRegular, FilterRegular } from "@fluentui/react-icons"; import Paginator from "../../components/pagination/Pagination";
import PanelHeader from "../../components/panel-header/PanelHeader";
import useDebounce from "../../utils/Debounce";
import usePolicyCoverageListStyles from "./PolicyCoverageListStyles";
import { useNavigate } from "react-router-dom";
import { getSkippedItems } from "../../utils/PaginationHelper";

interface PolicyCoverageListProps {
    policyId: string;
}

const PolicyCoverageList: React.FunctionComponent<PolicyCoverageListProps> = (props: PolicyCoverageListProps) => {

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const styles = usePolicyCoverageListStyles();
    const { mutate } = useMutation(endCoverages,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['policies-coverages', props.policyId]);
                setListState(state => ({ ...state, endClaimModal: { ...state.endClaimModal, isOpen: false, isProcessingRequest: false }, skipCoveragesAmount: 0 }));
            }
        });

    const [listState, setListState] = useState({
        selectedRowId: null as string | null,
        skipCoveragesAmount: 0,
        filters: {
            objectIdentifierFilter: '',
            displayed: false,
            onlyActive: true
        },
        endClaimModal: {
            isOpen: false,
            coverageGroupId: '',
            coveredObjects: '',
            insuranceTypes: '',
            isProcessingRequest: false
        }
    });
    const debouncedFilder = useDebounce(listState.filters.objectIdentifierFilter, 500);

    const { isLoading, data, isError } = useQuery({
        queryKey: ['policies-coverages', props.policyId, listState.skipCoveragesAmount, listState.filters, debouncedFilder],
        queryFn: () => fetchCoverages(
            props.policyId,
            { skip: listState.skipCoveragesAmount, take: 10 },
            { objectIdentifier: listState.filters.objectIdentifierFilter, active: listState.filters.onlyActive ? true : undefined }
        ),
        refetchOnWindowFocus: false,
        staleTime: 30000
    });

    const displayCoverages = (rowId: TableRowId) => {
        var selectedRowValue = rowId.toString() === listState.selectedRowId ? null : rowId.toString()
        setListState(state => ({ ...state, selectedRowId: selectedRowValue }));
    }

    const handleCancellClick = (group: CoverageGroup) => {
        const coveredObjects = Array.from(new Set(
            group.coverages
                .filter(coverage => coverage.coverage_instances !== null)
                .map(coverage => coverage.current_instance.covered.identifier)
        )).join(', ');

        const insuranceTypes = Array.from(new Set(
            group.coverages
                .filter(coverage => coverage.coverage_instances !== null)
                .map(coverage => coverage.name)
        )).join(', ');

        const modalState = {
            isOpen: true,
            coverageGroupId: group.id,
            coveredObjects: coveredObjects,
            insuranceTypes: insuranceTypes,
            isProcessingRequest: false
        };

        setListState(state => ({ ...state, endClaimModal: modalState }));
    }

    const onModifyCoverageClick = (coverageId: string) => {
        navigate(`/policies/${props.policyId}/coverages/${coverageId}/modify`);
    }

    const onEndInsuranceClicked = () => {
        setListState(state => ({ ...state, endClaimModal: { ...state.endClaimModal, isProcessingRequest: true } }));
        mutate({
            policyId: props.policyId,
            coverageGroupdId: listState.endClaimModal.coverageGroupId
        });
    }

    const toggleFilters = () => {
        setListState(state => ({ ...state, filters: { ...state.filters, displayed: !state.filters.displayed } }));
    }

    const onObjectIdentifierFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setListState(state => ({ ...state, filters: { ...state.filters, objectIdentifierFilter: event.target.value }, skipCoveragesAmount: 0 }));
    }

    const clearFilters = () => {
        setListState(state => ({ ...state, filters: { ...state.filters, objectIdentifierFilter: '', onlyActive: false }, selectedRowId: null, skipCoveragesAmount: 0 }));
    }

    const onPaginationClicked = (pageNumber: number) => {
        setListState(state => ({ ...state, skipCoveragesAmount: getSkippedItems(pageNumber, 10) }));
    }

    const onNewCoverageButtonClicked = () => {
        navigate(`/policies/${props.policyId}/coverages/new`);
    }

    const columns: TableColumnDefinition<CoverageGroup>[] = [
        createTableColumn<CoverageGroup>({
            columnId: "Insured",
            renderHeaderCell: () => {
                return "Insured";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.insured == null ? "-" : item.insured.first_name + " " + item.insured.last_name}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<CoverageGroup>({
            columnId: "CoveredObjects",
            renderHeaderCell: () => {
                return "Covered objects";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {
                            Array.from(new Set(
                                item.coverages
                                    .filter(coverage => coverage.coverage_instances !== null)
                                    .map(coverage => coverage.current_instance.covered.identifier)
                            )).join(', ')
                        }
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<CoverageGroup>({
            columnId: "Types",
            renderHeaderCell: () => {
                return "Coverage types";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {
                            Array.from(new Set(
                                item.coverages
                                    .filter(coverage => coverage.coverage_instances !== null)
                                    .map(coverage => coverage.name)
                            )).join(', ')
                        }
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<CoverageGroup>({
            columnId: "Period",
            renderHeaderCell: () => {
                return "Period";
            },
            renderCell: (item) => {
                var startDate = item.coverages
                    .map(coverage => new Date(coverage.start_date))
                    .reduce((max, date) => date > max ? date : max, new Date(0));

                const endDates = item.coverages
                    .filter(coverage => coverage.end_date !== null)
                    .map(coverage => new Date(coverage.end_date!));
                let endDateDisplayValue = '';
                if (endDates.length > 0) {
                    endDateDisplayValue = endDates.reduce((max, date) => date > max ? date : max,).toLocaleDateString();
                }

                return (
                    <TableCellLayout >
                        {startDate.toLocaleDateString()} - {endDateDisplayValue}
                    </TableCellLayout >
                )
            },
        }), createTableColumn<CoverageGroup>({
            columnId: "actions",
            renderHeaderCell: () => {
                return "Actions";
            },
            renderCell: (item) => {
                const isClaimEnded = item.coverages
                    .filter(coverage => coverage.current_instance !== null)
                    .map(coverage => coverage.current_instance)
                    .filter(coverage => coverage.end_date == null)
                    .length > 0;

                return (
                    <>
                        <Tooltip content="Show coverages" relationship="label">
                            <Button className={styles.actionButton} onClick={() => displayCoverages(item.id)} aria-label="Show" icon={<DocumentBulletListMultipleRegular />} />
                        </Tooltip>
                        {isClaimEnded &&
                            <>
                                <DialogTrigger disableButtonEnhancement>
                                    <Tooltip content="Modify coverages" relationship="label">
                                        <Button className={styles.actionButton} onClick={() => onModifyCoverageClick(item.id)} aria-label="End" icon={<DocumentEditRegular />} />
                                    </Tooltip>
                                </DialogTrigger>
                                <DialogTrigger disableButtonEnhancement>
                                    <Tooltip content="End coverages" relationship="label">
                                        <Button className={styles.actionButton} onClick={() => handleCancellClick(item)} aria-label="End" icon={<DocumentDismissRegular />} />
                                    </Tooltip>
                                </DialogTrigger>
                            </>}
                    </>
                );
            },
        })
    ];

    return (
        <div>
            <PanelHeader title={`Coverages`} type="secondary">
                <div>
                    <Tooltip content="Add coverage" relationship="label">
                        <Button appearance="secondary" className={styles.iconButton} onClick={onNewCoverageButtonClicked}><AddRegular className={styles.icon} /></Button>
                    </Tooltip>
                    <Tooltip content="Filter" relationship="label">
                        <Button appearance="secondary" className={styles.iconButton} onClick={toggleFilters}><FilterRegular className={styles.icon} /></Button>
                    </Tooltip>
                </div>
            </PanelHeader>

            <Dialog open={listState.endClaimModal.isOpen}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>End coverages</DialogTitle>
                        <DialogContent>
                            <MessageBar
                                layout="singleline"
                                intent="warning">
                                <MessageBarBody>
                                    This action is irreversible
                                </MessageBarBody>
                            </MessageBar>
                            <p>Are you sure you want to cancell coverages for following objects:</p>
                            <p><b>Objects:</b> {listState.endClaimModal.coveredObjects}</p>
                            <p><b>Coverage types:</b> {listState.endClaimModal.insuranceTypes}</p>
                            <p>This action takes some time, and it will not be reflected imidiatelly on UI</p>
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement >
                                <Button appearance="secondary" onClick={
                                    () => {
                                        setListState(state => ({ ...state, endClaimModal: { ...state.endClaimModal, isOpen: false } }));
                                    }
                                }>Close</Button>
                            </DialogTrigger>
                            {listState.endClaimModal.isProcessingRequest && <Button appearance="primary"><Spinner /></Button>}
                            {!listState.endClaimModal.isProcessingRequest && <Button appearance="primary" onClick={onEndInsuranceClicked}>End coverages</Button>}
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>

            {listState.filters.displayed &&
                <Card className={styles.searchBarWrapper}>
                    <Input placeholder="Subject identifier" value={listState.filters.objectIdentifierFilter} onChange={onObjectIdentifierFilterChange} className={styles.searchBar} />
                    <Checkbox label="Active" checked={listState.filters.onlyActive} onChange={() => setListState(state => ({ ...state, filters: { ...state.filters, onlyActive: !state.filters.onlyActive }, skipCoveragesAmount: 0 }))} />
                    <Tooltip content="Clear filters" relationship="label">
                        <Button appearance="secondary" className={styles.iconButton} onClick={clearFilters}><FilterDismissRegular className={styles.icon} /></Button>
                    </Tooltip>
                </Card>
            }

            {isLoading && <Spinner />}
            {isError && <ErrorBar message='Something went wrong during coverages load. Please refresh page or contact administrator' />}
            {!isLoading && !isError &&

                <Card>
                    <DataGrid
                        items={data?.items!}
                        columns={columns}
                        focusMode="composite"
                        style={{ minWidth: "550px" }}
                    >
                        <DataGridHeader>
                            <DataGridRow >
                                {({ renderHeaderCell }) => (
                                    <DataGridHeaderCell><b>{renderHeaderCell()}</b></DataGridHeaderCell>
                                )}
                            </DataGridRow>
                        </DataGridHeader>
                        <DataGridBody<CoverageGroup>>
                            {({ item, rowId }) => (
                                <div>
                                    <DataGridRow<CoverageGroup>
                                        key={rowId}
                                        className={`cursor-pointer ${listState.selectedRowId === rowId.toString() ? 'active-table-item' : ''}`}
                                    >
                                        {({ renderCell }) => (
                                            <DataGridCell>{renderCell(item)}</DataGridCell>
                                        )}
                                    </DataGridRow>
                                    {listState.selectedRowId === item.id && <CoverageInstancesList coverageGroup={item} />}
                                </div>
                            )}
                        </DataGridBody>
                    </DataGrid>
                    <Paginator pagination={data!.pagination} onButtonClicked={pageNumber => onPaginationClicked(pageNumber)} />
                </Card>
            }
        </div>
    )
}

export default PolicyCoverageList;
