import { makeStyles } from "@fluentui/react-components";

const usePolicyDetailsStyles = makeStyles({
    headerWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "16px",
    },
    card: {
        flex: 1,
        maxWidth: "100%",
        height: "fit-content",
        minHeight: "120px"
    },
    text: {
        margin: "0",
        display: "flex",
        flexDirection: "column",
    },
});

export default usePolicyDetailsStyles;