import { Radio, Slider } from "@fluentui/react-components";
import Attribute from "../../entities/concepts/Attribute";

interface RangeAttributeModel {
    unit: string;
    max_value: number;
    min_value: number;
    is_to_in_inclusive: boolean;
}

interface RangeAttributeProps {
    attribute: Attribute
    selected: boolean
    onClick: (attribute: Attribute) => void
}

const RangeAttribute: React.FunctionComponent<RangeAttributeProps> = (props) => {
    const rangeAttribute = props.attribute.value as RangeAttributeModel;
    return (
        <div >
            <Radio
                value={props.attribute.code}
                label={props.attribute.display_name}
                checked={props.selected}
                onClick={() => props.onClick(props.attribute)}
            />

            {props.selected &&
                <Slider defaultValue={rangeAttribute.max_value} max={rangeAttribute.max_value} min={rangeAttribute.min_value} />
            }
        </div>)
}

export default RangeAttribute;