import { DocumentBulletListMultipleRegular, DocumentBulletListRegular } from "@fluentui/react-icons";
import PanelHeader from "../../components/panel-header/PanelHeader";
import useDashboardStyles from "./DashboardPageStyles";
import { Card, CardHeader, Label } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";

const Dashboard: React.FunctionComponent = () => {
    const styles = useDashboardStyles();
    const navigate = useNavigate();

    return (
        <div>
            <PanelHeader title={`Dashboard`} />
            <div className={styles.dashboardOptions}>
                <Card className={styles.optionCard} onClick={()=> navigate('/agreements')}>
                    <CardHeader className={styles.optionCardHeader}
                        header={
                            <DocumentBulletListRegular />
                        }
                    >
                    </CardHeader>
                    <p>
                        <Label>Agreements</Label>
                    </p>
                </Card>
                <Card className={styles.optionCard}  onClick={()=> navigate('/policies')}>
                    <CardHeader className={styles.optionCardHeader}
                        header={
                            <DocumentBulletListMultipleRegular />
                        }
                    >
                    </CardHeader>
                    <p>
                        <Label>Policies</Label>
                    </p>
                </Card>
            </div>
        </div >
    );
}

export default Dashboard;