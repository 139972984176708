import { createReducer } from '@reduxjs/toolkit';
import { login, logout } from './Actions';
import Cookies from 'js-cookie';

interface AuthState {
  isAuthenticated: boolean;
  user: string | null;
  accessTokenExpirationDate: number | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  accessTokenExpirationDate: null
};

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(login, (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.UserName;
      state.accessTokenExpirationDate = action.payload.TokenExpirationDate;
      Cookies.remove('Authorization');
      Cookies.set(
        'Authorization',
        `Bearer ${action.payload.Token}`,
        {
          expires: action.payload.TokenExpirationDate,
          secure: true,
          sameSite: 'strict'
        }
      );
    })
    .addCase(logout, (state) => {
      state.isAuthenticated = false;
      state.user = null;
    });
});

export default authReducer;