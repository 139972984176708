import { makeStyles } from "@fluentui/react-components";

const usePolicyListStyles = makeStyles({
    iconButton: {
        margin: "0 4px",
        width: "32px",
        minWidth: "32px",
        minHeight: "32px",
        height: "32px",
        padding: "4px",
    }
})

export default usePolicyListStyles;