import { Button, makeStyles, mergeClasses } from '@fluentui/react-components';
import { Pagination } from '../../entities/Pagination';
import { ChevronDoubleLeftRegular, ChevronDoubleRightRegular } from '@fluentui/react-icons';

interface PaginationProps {
    pagination: Pagination;
    onButtonClicked: (pageNumber: number) => void;
}

const useStyles = makeStyles({
    paginationButton: {
        margin: "0 4px",
        width: "32px",
        minWidth: "32px",
    },
    paginationWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "right",
        marginTop: "8px",
    }
});

const Paginator: React.FC<PaginationProps> = ({ pagination, onButtonClicked }) => {
    const styles = useStyles();
    const displayFirstPageButton = pagination.previous_page && pagination.previous_page !== 1;
    const displayPreviousPageButton = pagination.previous_page;
    const displayCurrentPageButton = pagination.current_page;
    const displayNextPageButton = pagination.next_page;
    const displayLastPageButton = pagination.total_pages !== 0 && pagination.total_pages !== pagination.current_page && pagination.total_pages !== pagination.current_page + 1;

    return (
        <div className={styles.paginationWrapper}>
            {displayFirstPageButton && <Button onClick={() => onButtonClicked(1)} className={styles.paginationButton} appearance="secondary"><ChevronDoubleLeftRegular /></Button>}
            {displayPreviousPageButton && <Button onClick={() => onButtonClicked(pagination.previous_page)} className={styles.paginationButton} appearance="secondary"> {pagination.previous_page} </Button>}
            {displayCurrentPageButton && <Button className={styles.paginationButton} appearance="primary"> {pagination.current_page} </Button>}
            {displayNextPageButton && <Button onClick={() => onButtonClicked(pagination.next_page)} className={styles.paginationButton} appearance="secondary"> {pagination.next_page} </Button>}
            {displayLastPageButton && <Button onClick={() => onButtonClicked(pagination.total_pages)} className={styles.paginationButton} appearance="secondary"><ChevronDoubleRightRegular /></Button>}
        </div>
    );
}

export default Paginator;