import Cookies from "js-cookie";
import PaginatedModel from "../entities/PaginatedModel";
import { Policy } from "../entities/Policy";
import { PaginationRequest } from "../entities/requests/PaginationRequest";

const apiUrl = `${process.env.REACT_APP_PAPI_URL}/policies`;

export const fetchPolicies = async (pagination: PaginationRequest): Promise<PaginatedModel<Policy>> => {
    const response = await fetch(`${apiUrl}?$skip=${pagination.skip}&$take=${pagination.take}`, {
        headers: {
            Authorization: Cookies.get('Authorization') || ''
        },
        method: 'GET',
        credentials: 'same-origin',
    });

    if (!response.ok) {
        throw new Error("Failed to get policies");
    }

    return response.json();
}

export const fetchPolicy = async (policyId: string): Promise<Policy> => {
    const response = await fetch(`${apiUrl}/${policyId}`, {
        headers: {
            Authorization: Cookies.get('Authorization') || ''
        },
        method: 'GET',
        credentials: 'same-origin',
    });


    if (!response.ok) {
        throw new Error("Failed to get policy");
    }

    return response.json();
}

export const endPolicy = async (props: { policyId: string }) => {
    const response = await fetch(`${apiUrl}/${props.policyId}`, {
        headers: {
            Authorization: Cookies.get('Authorization') || ''
        },
        method: 'DELETE',
        credentials: 'same-origin',
    });

    if (!response.ok) {
        const errorResponse = await response.json();
        if (response.status === 400) {
            throw errorResponse;
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
}