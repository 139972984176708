import { MessageBar, MessageBarBody, MessageBarTitle } from "@fluentui/react-components";

export interface ErrorBarProps {
    message: string;
}

const ErrorBar: React.FunctionComponent<ErrorBarProps> = (props) => {

    return (<MessageBar intent="error">
        <MessageBarBody>
            <MessageBarTitle>Error</MessageBarTitle>
            {props.message}
        </MessageBarBody>
    </MessageBar>);
}

export default ErrorBar;