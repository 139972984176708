import Cookies from "js-cookie";
import Bundle from "../entities/concepts/Bundle";

const apiUrl = `${process.env.REACT_APP_PAPI_URL}/bundles`;

export const fetchBundle = async (bundleCode: string): Promise<Bundle> => {
    const response = await fetch(`${apiUrl}/${bundleCode}`, {
        headers: {
            Authorization: Cookies.get('Authorization') || ''
        },
        method: 'GET',
        credentials: 'same-origin',
    });

    if (!response.ok) {
        throw new Error("Failed to get bundle");
    }

    return response.json();
}