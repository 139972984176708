import { makeStyles } from "@fluentui/react-components";

const useLandingPageStyles = makeStyles({
    landingPageLogo: {
        minHeight: "calc(30%)",
        backgroundImage: "url('/landing-page-background.jpg')",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
    },
    landingPageOptionsHeader: {
        marginTop: "60px",
        marginBottom: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    landingPageOptions: {
        padding: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
    },
    optionCard: {
        flex: 1,
        height: "fit-content",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
    },
    optionCardHeader: {
        fontSize: "60px",
        marginBottom: "10px",
    }
});
export default useLandingPageStyles;