import { useQuery } from 'react-query';
import { fetchAgreements } from '../../apis/AgreementsApi';
import { Button, Card, createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, Spinner, TableCellLayout, TableColumnDefinition, Tooltip } from '@fluentui/react-components';
import Agreement from '../../entities/Agreement';
import PanelHeader from '../../components/panel-header/PanelHeader';
import ErrorBar from '../../components/error-bar/ErrorBar';
import { useState } from 'react';
import Paginator from '../../components/pagination/Pagination';
import { DocumentBulletListMultipleRegular } from '@fluentui/react-icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAgreementListStyles from './AgreementListStyles';
import { getSkippedItems } from '../../utils/PaginationHelper';

const AgreementsList: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const style = useAgreementListStyles();
    const pageSize = 22;
    const [paginationParams, setPagination] = useSearchParams({ page: '1' });
    const currentPage = parseInt(paginationParams.get('page') || '1');
    const [skipListItems, setSkipListItems] = useState(getSkippedItems(currentPage, pageSize));

    const columns: TableColumnDefinition<Agreement>[] = [
        createTableColumn<Agreement>({
            columnId: "Id",
            renderHeaderCell: () => {
                return "Id";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.id}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Agreement>({
            columnId: "StartDate",
            renderHeaderCell: () => {
                return "Start Date";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {new Date(item.start_date).toDateString() || "-"}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Agreement>({
            columnId: "EndDate",
            renderHeaderCell: () => {
                return "End Date";
            },

            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {new Date(item.end_date).toDateString() || "-"}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Agreement>({
            columnId: "Administrator",
            renderHeaderCell: () => {
                return "Administrator";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.administrator.first_name} {item.administrator.last_name}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Agreement>({
            columnId: "actions",
            renderHeaderCell: () => {
                return "Actions";
            },
            renderCell: (item) => {

                return (
                    <Tooltip content="Agreement details" relationship="label">
                        <Button onClick={() => handleRowClick(item.id)} aria-label="Show" icon={<DocumentBulletListMultipleRegular />} />
                    </Tooltip>
                );
            },
        })
    ];

    const { isLoading, data, isError } = useQuery({
        queryKey: ['agreements', skipListItems],
        queryFn: () => fetchAgreements({ skip: skipListItems, take: pageSize }),
        refetchOnWindowFocus: false
    });

    const handleRowClick = (id: string) => {
        navigate('/agreements/' + id);
    }

    const onPaginationClicked = (pageNumber: number) => {
        setSkipListItems(getSkippedItems(pageNumber, pageSize));
        setPagination({ page: pageNumber.toString() });
    }

    return (
        <div className={style.agreementListWrapper}>
            <PanelHeader title="Agreements" />
            {isLoading && <Spinner />}
            {isError && <ErrorBar message='Something went wrong during agreements load. Please refresh page or contact administrator' />}

            {!isLoading && !isError &&
                <Card className={style.tableWrapper}>
                    <DataGrid
                        items={data?.items || []}
                        columns={columns}
                        focusMode="composite"
                        style={{ minWidth: "550px" }}
                    >
                        <DataGridHeader>
                            <DataGridRow >
                                {({ renderHeaderCell }) => (
                                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                )}
                            </DataGridRow>
                        </DataGridHeader>
                        <DataGridBody<Agreement>>
                            {({ item, rowId }) => (
                                <DataGridRow<Agreement>
                                    key={rowId}
                                    className='cursor-pointer'
                                >
                                    {({ renderCell }) => (
                                        <DataGridCell>{renderCell(item)}</DataGridCell>
                                    )}
                                </DataGridRow>
                            )}
                        </DataGridBody>
                    </DataGrid>
                    <Paginator pagination={data!.pagination} onButtonClicked={pageNumber => onPaginationClicked(pageNumber)} />
                </Card>
            }
        </div>
    );
}

export default AgreementsList;