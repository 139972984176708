import * as React from 'react';
import { Image } from '@fluentui/react-components';
import Authentication from '../auth/Authentication';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from '../../store/Hooks';
import useNavbarStyles from './NavbarStyles';
import { BoardRegular, DocumentBulletListMultipleRegular, DocumentBulletListRegular } from '@fluentui/react-icons';

const Navbar: React.FunctionComponent = () => {

    const authState = useAppSelector(state => state.auth);
    const styles = useNavbarStyles();

    return (
        <div>
            <div className={styles.sidebar}>
                <Image src="/logo.svg" alt="Logo" className={styles.sidebarLogo} />
                <ul>
                    {authState.isAuthenticated && (
                        <>
                            <li><NavLink className={({ isActive }) => (isActive ? styles.navActive : "")} to={'/dashboard'}><BoardRegular /> Dashboard</NavLink></li>
                            <li><NavLink className={({ isActive }) => (isActive ? styles.navActive : "")} to={'/agreements'}><DocumentBulletListRegular />Agreements</NavLink></li>
                            <li><NavLink className={({ isActive }) => (isActive ? styles.navActive : "")} to={'/policies'}><DocumentBulletListMultipleRegular />Policies</NavLink></li>
                        </>
                    )}
                    <li><Authentication /></li>
                </ul>
            </div>
        </div>
    );
};


export default Navbar;