import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../store/Hooks";
import React from "react";

interface ProtectedRouteProps {
    children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const authState = useAppSelector(state => state.auth);
    if (!authState.isAuthenticated) {
        return <Navigate to="/" />;
    }
    return children;
};

export default ProtectedRoute;