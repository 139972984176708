import { useParams } from "react-router-dom";
import { fetchAgreementPolicies } from "../../apis/AgreementsApi";
import { Button, Card, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, MessageBar, MessageBarBody, Spinner } from "@fluentui/react-components";
import ErrorBar from "../../components/error-bar/ErrorBar";
import { useMutation, useQuery, useQueryClient } from "react-query";
import PoliciesList from "../../components/policies-list/PoliciesList";
import { useState } from "react";
import Paginator from "../../components/pagination/Pagination";
import { endPolicy } from "../../apis/PoliciesApi";
import { Policy } from "../../entities/Policy";
import { DocumentDismissRegular } from "@fluentui/react-icons";
import { getSkippedItems } from "../../utils/PaginationHelper";

const AgreementPoliciesList: React.FunctionComponent = () => {
    const { agreementId } = useParams();
    const queryClient = useQueryClient();
    const [state, setState] = useState(
        {
            selectedRowId: null as string | null,
            skipAmount: 0,
            filters: {
                objectIdentifierFilter: '',
                displayed: false,
                onlyActive: true
            },
            terminatePolicyModal: {
                isOpen: false,
                policyId: '',
                isProcessingRequest: false
            }
        }
    );

    const { isLoading, data, isError } = useQuery({
        queryKey: ['agreement-policies', agreementId, state.skipAmount],
        queryFn: () => fetchAgreementPolicies(agreementId!, { skip: state.skipAmount, take: 10 }),
        refetchOnWindowFocus: false
    });

    const { mutate } = useMutation(endPolicy,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['agreement-policies', agreementId, state.skipAmount]);
                setState(state => ({ ...state, terminatePolicyModal: { ...state.terminatePolicyModal, isOpen: false, isProcessingRequest: false }, skipAmount: 0 }));
            }
        });

    const onPaginationClicked = (pageNumber: number) => {
        setState(state => ({ ...state, skipAmount: getSkippedItems(pageNumber, 10) }));
    }

    const onEndInsuranceClicked = () => {
        setState(state => ({ ...state, terminatePolicyModal: { ...state.terminatePolicyModal, isProcessingRequest: true } }));
        mutate({ policyId: state.terminatePolicyModal.policyId });
    }

    const onTerminatePolicyClicked = (policy: Policy) => {
        setState(state => ({ ...state, terminatePolicyModal: { isOpen: true, policyId: policy.policy_id, isProcessingRequest: false } }));
    }

    if (isLoading) {
        return (
            <div>
                <Spinner></Spinner>
            </div>);
    }

    if (isError) {
        return (
            <div>
                <ErrorBar message='Something went wrong during agreements policiies load. Please refresh page or contact administrator' />
            </div>);
    }

    return (
        <>
            <Dialog open={state.terminatePolicyModal.isOpen}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Terminate policy</DialogTitle>
                        <DialogContent>
                            <MessageBar
                                layout="singleline"
                                intent="warning">
                                <MessageBarBody>
                                    This action is irreversible
                                </MessageBarBody>
                            </MessageBar>
                            <p>Are you sure you want to terminate policy <b> {state.terminatePolicyModal.policyId} </b></p>
                            <p>This action takes some time, and it will not be reflected imidiatelly on UI</p>
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement >
                                <Button appearance="secondary" onClick={
                                    () => {
                                        setState(state => ({ ...state, terminatePolicyModal: { ...state.terminatePolicyModal, isOpen: false } }));
                                    }
                                }>Close</Button>
                            </DialogTrigger>
                            {state.terminatePolicyModal.isProcessingRequest && <Button appearance="primary"><Spinner /></Button>}
                            {!state.terminatePolicyModal.isProcessingRequest && <Button appearance="primary" onClick={onEndInsuranceClicked}>Terminate policy</Button>}
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <Card>
                <PoliciesList items={data!} actions={[
                    {
                        columnId: "TerminatePolicy",
                        icon: <DocumentDismissRegular />,
                        onClick: (item: Policy) => onTerminatePolicyClicked(item),
                        toolTip: "Terminate policy"
                    }
                ]} />
                <Paginator pagination={data!.pagination} onButtonClicked={pageNumber => onPaginationClicked(pageNumber)} />
            </Card>
        </>
    )
};

export default AgreementPoliciesList;