import { Divider, mergeClasses, Link } from "@fluentui/react-components"
import useFooterStyles from "./FooterStyles";
import { useLocation } from "react-router-dom";

const Footer: React.FunctionComponent = () => {
    const styles = useFooterStyles();
    const location = useLocation();
    var footerStyle = styles.footer;

    if (location.pathname === "/") {
        footerStyle = mergeClasses(styles.footerPadding, styles.footer);
    }

    return (
        <div className={footerStyle}>
            <Divider />
            <div className={styles.footerSpan}>© Mysafety Försäkringar AB, Contact us:
                <Link appearance="subtle" href="mailto:partnersales@mysafety.se">
                    partnersales@mysafety.se
                </Link>
            </div>
        </div>
    );

}
export default Footer;