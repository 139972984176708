import { makeStyles } from "@fluentui/react-components";

const usePoliciesListViewStyles = makeStyles({
    policiesListWrapper: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxHeight: "100%",
    },
    tableWrapper: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "16px",
        justifyContent: "space-between",
    },
})

export default usePoliciesListViewStyles;