
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import usePolicyFormStyles from "./PolicyFormStyles";
import { NewPolicyHolder } from "../../entities/requests/NewPolicy/NewPolicyHolder";
import { Period } from "../../entities/Period";
import NewCoverageGroup from "../../entities/requests/NewPolicy/NewCoverageGroup";
import { Button, Card, Dropdown, Field, Input, mergeClasses, Switch, Option, Spinner } from "@fluentui/react-components";
import DateTimePicker from "../date-time-picker/DateTimePicker";
import { toSentenceCase } from "../../utils/StringUtils";
import { formatDateTimeString } from "../../utils/DateTimeUtils";
import ElementPicker from "../elements/ElementPicker";
import Bundle from "../../entities/concepts/Bundle";
import { isDate, isMoney, isNumber, isString } from "../../utils/FactorsUtils";
import Dictionary from "../../utils/Dictionary";
import Element from "../../entities/concepts/Element";

export type PolicyFormData = {
    holder: NewPolicyHolder | undefined;
    concept_code: string;
    period: Period;
    coverage_groups: NewCoverageGroup[];
}

type PolicyFormState = {
    includeHolder: boolean,
    includeInsured: boolean,
    holderIsInsured: boolean,
    productElements: Dictionary<any | undefined>[],
    initialized: boolean,
    displayProductElements: boolean[]
}

interface PolicyFormProps {
    bundle: Bundle
    onSubmit: (data: PolicyFormData) => void
    initialState: PolicyFormData
    isSubmitting: boolean
    isUpdate: boolean
}

const PolicyForm: React.FunctionComponent<PolicyFormProps> = (props: PolicyFormProps) => {
    //https://legacy.reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
    const styles = usePolicyFormStyles();
    const { register, handleSubmit, setValue, getValues, control } = useForm<PolicyFormData>({
        defaultValues: props.initialState
    });
    const initialValueIncludesHolder = props.initialState.holder !== null && props.initialState.holder !== undefined;
    const [formState, setFormState] = useState<PolicyFormState>({
        includeHolder: initialValueIncludesHolder,
        includeInsured: false,
        holderIsInsured: false,
        productElements: [],
        initialized: false,
        displayProductElements: []
    });

    const onFactorChanged = (coverageIndex: number, productIndex: number, factorName: string, value: any) => {
        setValue(`coverage_groups.${coverageIndex}.Coverages.${productIndex}.data.${factorName}`, value, { shouldTouch: true });
    }

    const onAttributeChanged = (productIndex: number, element: Element, value: any | null) => {
        if (value === null) {
            let newProductElements = formState.productElements;
            newProductElements[productIndex][element.code] = undefined;
            setFormState({ ...formState, productElements: newProductElements });
        } else {
            let newProductElements = formState.productElements;
            newProductElements[productIndex][element.code] = value;
            setFormState({ ...formState, productElements: newProductElements });
        }
    };

    //Lets assume that we have a concept with single bundle
    const products = props.bundle.products;

    useEffect(() => {
        if (!formState.initialized) {
            const elements = products!.map(product => {
                let elementDict: Dictionary<any | undefined> = {};
                product.elements.forEach(element => {
                    if (element.required) {
                        var defaultAttribute = product.attributes.find(x => x.code == element.attributes[0]);
                        elementDict[element.code] = defaultAttribute?.value;
                    } else {
                        elementDict[element.code] = undefined;
                    }
                });
                return elementDict;
            });
            const displayProductElements = products!.map((product) => {
                const anyElementWithMoreThanOneAttribute = product.elements.some(element => element.attributes.length > 1);
                return anyElementWithMoreThanOneAttribute;
            });
            setFormState({ ...formState, productElements: elements, initialized: true, displayProductElements: displayProductElements });
        }
    });

    if (!formState.initialized)
        return (<></>)

    return (
        <>
            <form onSubmit={handleSubmit((data) => {
                if (!formState.includeHolder) {
                    data.holder = undefined;
                }
                if (!formState.includeInsured) {
                    data.coverage_groups[0].Insured = undefined
                }
                props.onSubmit(data);
            })}>
                <Switch
                    label="Include holder data"
                    checked={formState.includeHolder}
                    onChange={() => {
                        setFormState(prevState => ({ ...prevState, includeHolder: !prevState.includeHolder }));
                    }}
                />
                <Switch
                    label="Include insured data"
                    checked={formState.includeInsured}
                    onChange={() => {
                        setFormState(prevState => ({ ...prevState, includeInsured: !prevState.includeInsured }));
                    }}
                />

                {formState.includeHolder &&
                    <Card className={mergeClasses(styles.userDataWrapper, styles.productCard)}>
                        <div>
                            <b>Holder information</b>
                            <Field label="Identification number" required>
                                <Input defaultValue={props.initialState.holder?.identification_number} {...register("holder.identification_number", { required: formState.includeHolder })} />
                            </Field>
                            <Field label="First Name" required>
                                <Input defaultValue={props.initialState.holder?.first_name} {...register("holder.first_name", { required: formState.includeHolder })} />
                            </Field>
                            <Field label="Last name" required>
                                <Input defaultValue={props.initialState.holder?.last_name} {...register("holder.last_name", { required: formState.includeHolder })} />
                            </Field>
                        </div>
                        <div>
                            <b>Contact</b>
                            <Field label="Email address">
                                <Input defaultValue={props.initialState.holder?.contact?.email_address} {...register("holder.contact.email_address")} />
                            </Field>
                            <Field label="Mobile phone number">
                                <Input defaultValue={props.initialState.holder?.contact?.mobile_phone_number} {...register("holder.contact.mobile_phone_number")} />
                            </Field>
                            <Field label="Home phone number">
                                <Input defaultValue={props.initialState.holder?.contact?.home_phone_number} {...register("holder.contact.home_phone_number")} />
                            </Field>
                        </div>
                        <div>
                            <b>Address</b>
                            <Field label="Street">
                                <Input defaultValue={props.initialState.holder?.billing_address?.street} {...register("holder.billing_address.street")} />
                            </Field>
                            <Field label="City">
                                <Input defaultValue={props.initialState.holder?.billing_address?.city} {...register("holder.billing_address.city")} />
                            </Field>
                            <Field label="Country">
                                <Input defaultValue={props.initialState.holder?.billing_address?.country} {...register("holder.billing_address.country")} />
                            </Field>
                            <Field label="Postal code">
                                <Input defaultValue={props.initialState.holder?.billing_address?.postal_code} {...register("holder.billing_address.postal_code")} />
                            </Field>
                        </div>
                    </Card>
                }

                {formState.includeInsured &&
                    <Card className={mergeClasses(styles.userDataWrapper, styles.productCard)}>
                        <div>
                            <b>Insured information</b>
                            <Field label="Identification number" required>
                                <Input defaultValue={props.initialState.coverage_groups[0].Insured?.identification_number} {...register("coverage_groups.0.Insured.identification_number", { required: formState.includeInsured })} />
                            </Field>
                            <Field label="First Name" required>
                                <Input defaultValue={props.initialState.coverage_groups[0].Insured?.first_name} {...register("coverage_groups.0.Insured.first_name", { required: formState.includeInsured })} />
                            </Field>
                            <Field label="Last name" required>
                                <Input defaultValue={props.initialState.coverage_groups[0].Insured?.last_name} {...register("coverage_groups.0.Insured.last_name", { required: formState.includeInsured })} />
                            </Field>
                        </div>
                        <div>
                            <b>Contact</b>
                            <Field label="Email address">
                                <Input defaultValue={props.initialState.coverage_groups[0].Insured?.contact?.email_address} {...register("coverage_groups.0.Insured.contact.email_address")} />
                            </Field>
                            <Field label="Mobile phone number">
                                <Input defaultValue={props.initialState.coverage_groups[0].Insured?.contact?.mobile_phone_number} {...register("coverage_groups.0.Insured.contact.mobile_phone_number")} />
                            </Field>
                            <Field label="Home phone number">
                                <Input defaultValue={props.initialState.coverage_groups[0].Insured?.contact?.home_phone_number} {...register("coverage_groups.0.Insured.contact.home_phone_number")} />
                            </Field>
                        </div>
                        <div>
                            <b>Address</b>
                            <Field label="Street">
                                <Input defaultValue={props.initialState.coverage_groups[0].Insured?.address?.street} {...register("coverage_groups.0.Insured.address.street")} />
                            </Field>
                            <Field label="City">
                                <Input defaultValue={props.initialState.coverage_groups[0].Insured?.address?.city} {...register("coverage_groups.0.Insured.address.city")} />
                            </Field>
                            <Field label="Country">
                                <Input defaultValue={props.initialState.coverage_groups[0].Insured?.address?.country} {...register("coverage_groups.0.Insured.address.country")} />
                            </Field>
                            <Field label="Postal code">
                                <Input defaultValue={props.initialState.coverage_groups[0].Insured?.address?.postal_code} {...register("coverage_groups.0.Insured.address.postal_code")} />
                            </Field>
                        </div>
                    </Card>
                }


                <div className={styles.productsWrapper}>
                    {products!.map((product, index) => {
                        const subjectType = getValues(`coverage_groups.0.Coverages.${index}.subject.type`);
                        let pickedSubjectType = product.subject_types.find(x => x.code === subjectType);
                        if (!pickedSubjectType) {
                            pickedSubjectType = product.subject_types[0];
                        }

                        return (
                            <Card key={product.code} className={styles.productCard}>
                                <div className={styles.productHeaderWrapper}><b>{product.name}</b>
                                    <Switch
                                        label="Display elements"
                                        checked={formState.displayProductElements[index]}
                                        onChange={() => {
                                            let newDisplayProductElements = formState.displayProductElements;
                                            newDisplayProductElements[index] = !newDisplayProductElements[index];
                                            setFormState({ ...formState, displayProductElements: newDisplayProductElements });
                                        }}
                                    />
                                </div>
                                <Dropdown
                                    disabled={props.isUpdate}
                                    defaultValue={pickedSubjectType.name}
                                    defaultSelectedOptions={[pickedSubjectType.code]}
                                    {...register(`coverage_groups.0.Coverages.${index}.subject.type`)}
                                >
                                    {product.subject_types.map(subjectType =>
                                        <Option key={subjectType.code} value={subjectType.code}>{subjectType.name}</Option>
                                    )}
                                </Dropdown>
                                <Field required label="Subject identifier">
                                    <Input disabled={props.isUpdate} defaultValue={props.initialState.coverage_groups[0].Coverages[index].subject.identifier} {...register(`coverage_groups.0.Coverages.${index}.subject.identifier`, { required: true })} />
                                </Field>
                                {formState.displayProductElements[index] &&
                                    <div className={styles.elementsWrapper}>
                                        {product.elements.map(element => {
                                            var attributes = product.attributes.filter(attribute => element.attributes.includes(attribute.code));
                                            const selectedAttribute = formState.productElements[index][element.code] ?? null;
                                            return (
                                                <ElementPicker
                                                    key={element.code}
                                                    element={element}
                                                    attributes={attributes}
                                                    initiallySelectedAttributeValue={selectedAttribute}
                                                    onSelected={(element, attribute) => onAttributeChanged(index, element, attribute?.value)} />
                                            )
                                        })}
                                    </div>
                                }

                                <Controller
                                    name={`coverage_groups.0.Coverages.${index}.coverage_duration.start`}
                                    control={control}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <DateTimePicker
                                            required
                                            label="Coverage start"
                                            value={value}
                                            onChange={(date) => {
                                                onChange(date);
                                                setValue(`coverage_groups.0.Coverages.${index}.coverage_duration.start`, date, { shouldTouch: true });
                                            }}
                                        />
                                    )}
                                />

                                <Controller
                                    name={`coverage_groups.0.Coverages.${index}.coverage_duration.end`}
                                    control={control}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <DateTimePicker
                                            required
                                            label="Coverage end"
                                            value={value}
                                            onChange={(date) => {
                                                onChange(date);
                                                setValue(`coverage_groups.0.Coverages.${index}.coverage_duration.end`, date, { shouldTouch: true });
                                            }}
                                        />
                                    )}
                                />

                                {product.factors.length !== 0 &&
                                    <>
                                        <b>Insurance data</b>
                                        {product.factors.map(factor => {
                                            const factorDefinition = props.bundle.factors.find(f => f.name === factor)!;

                                            const factorValue = getValues(`coverage_groups.0.Coverages.${index}.data.${factorDefinition.name}`);
                                            const displayName = toSentenceCase(factorDefinition.display_name);
                                            return (
                                                <div key={factor}>
                                                    {isDate(factorDefinition) &&
                                                        <DateTimePicker
                                                            required={factorDefinition.required}
                                                            label={displayName}
                                                            value={factorValue === '' ? new Date() : new Date(factorValue)}
                                                            onChange={(date) => onFactorChanged(0, index, factorDefinition.name, formatDateTimeString(date))}
                                                        />
                                                    }
                                                    {isString(factorDefinition) &&
                                                        <Field label={displayName} required={factorDefinition.required}>
                                                            <Input
                                                                defaultValue={factorValue}
                                                                onChange={(e) => onFactorChanged(0, index, factorDefinition.name, e.target.value)}
                                                            />
                                                        </Field>
                                                    }
                                                    {isMoney(factorDefinition) &&
                                                        <Field required={factorDefinition.required} label={displayName}>
                                                            <Input
                                                                type="number"
                                                                contentAfter={process.env.REACT_APP_CURRENCY}
                                                                defaultValue={factorValue.Value}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    onFactorChanged(0, index, factorDefinition.name, { value: value, currency: process.env.REACT_APP_CURRENCY });
                                                                }} />
                                                        </Field>
                                                    }
                                                    {isNumber(factorDefinition) &&
                                                        <Field required={factorDefinition.required} label={displayName}>
                                                            <Input
                                                                type="number"
                                                                defaultValue={factorValue}
                                                                min={0}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    onFactorChanged(0, index, factorDefinition.name, Number(value));
                                                                }} />
                                                        </Field>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                            </Card>
                        )
                    })}
                </div>
                <Button className={styles.submitButton} appearance="primary" type="submit" disabled={props.isSubmitting}> {props.isSubmitting && <Spinner />} {props.isUpdate ? "Update" : "Save"}</Button>
            </form>
        </ >
    );
}

export default PolicyForm;