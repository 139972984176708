import { useNavigate, useParams } from "react-router-dom";
import PanelHeader from "../../components/panel-header/PanelHeader";
import { fetchPolicy } from "../../apis/PoliciesApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import { Spinner, Button, Tooltip, MessageBar, MessageBarBody } from "@fluentui/react-components";
import { addCoverages } from "../../apis/CoveragesApi";
import ErrorBar from "../../components/error-bar/ErrorBar";
import ErrorResponse from "../../entities/response/ErrorResponse";
import { ArrowLeftRegular } from "@fluentui/react-icons";
import { fetchBundle } from "../../apis/BundlesApi";
import CoverageForm, { CoverageFormData } from "../../components/coverage-form/CoverageForm";
import useAddCoverageStyles from "./AddCoverageStyles";
import Dictionary from "../../utils/Dictionary";
import { isDate, isMoney } from "../../utils/FactorsUtils";

type AddCoverageState = {
    isSubmitting: boolean,
    initialized: boolean,
    errors: string[]
    coverageFormData: CoverageFormData
}

const AddCoverage: React.FunctionComponent = () => {
    const queryClient = useQueryClient();
    const styles = useAddCoverageStyles();
    const { policyId } = useParams();
    const navigate = useNavigate();
    const [formState, setFormState] = useState<AddCoverageState>({
        isSubmitting: false,
        initialized: false,
        errors: [],
        coverageFormData: { Insured: undefined, Coverages: [] }
    });
    const { mutate, isError } = useMutation(addCoverages,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['policies-coverages', policyId]);
                navigate('/policies/' + policyId);
            },
            onError: (error) => {
                if ((error as ErrorResponse).errors) {
                    const errorMessages = (error as ErrorResponse).errors.map(x => x.message);
                    setFormState({ ...formState, isSubmitting: false, errors: errorMessages });
                } else {
                    const errorMessages = ["Unknow error occured. Please try again later or contact administrator."];
                    setFormState({ ...formState, isSubmitting: false, errors: errorMessages });
                }
            }
        });

    const { isLoading: isLoadingPolicy, data: policy, isError: policyLoadingError } = useQuery({
        queryKey: ['policy', policyId],
        queryFn: () => fetchPolicy(policyId!),
        refetchOnWindowFocus: false
    });

    const { isLoading: isLoadingBundle, data: bundle, isError: bundleLoadingError } = useQuery({
        queryKey: ['concept', policy?.concept_code],
        queryFn: () => fetchBundle(policy!.concept_code),
        enabled: policy?.concept_code !== undefined,
        refetchOnWindowFocus: false
    });

    const onSubmit = (data: CoverageFormData) => {
        setFormState({ ...formState, isSubmitting: true });
        mutate({ policyId: policyId!, request: { Coverages: data.Coverages, Insured: data.Insured } });
    }

    /// Initialize form with products from bundle
    useEffect(() => {
        if (!formState.initialized && !isLoadingBundle && bundle) {

            let coverageFormData: CoverageFormData =
            {
                Insured: undefined,
                Coverages: []
            };
            const products = bundle.products;
            products?.map((product, index) => {
                const factorsDictionary = {} as Dictionary<string>;
                product.factors.forEach(factor => {
                    const factorDefinition = bundle?.factors.find(f => f.name === factor)!;
                    let factorValue: any = '';

                    if (isDate(factorDefinition)) {
                        const date = new Date();
                        date.setHours(12);
                        date.setMinutes(0);
                        date.setSeconds(0);
                        factorValue = date;
                    } else if (isMoney(factorDefinition)) {
                        factorValue = {
                            value: 0,
                            currency: process.env.REACT_APP_CURRENCY
                        };
                    }
                    factorsDictionary[factor] = factorValue;
                });
                const endDate = new Date();
                endDate.setMonth(endDate.getMonth() + 1);

                coverageFormData.Coverages.push({
                    type: product.code,
                    data: factorsDictionary,
                    coverage_duration: {
                        end: endDate,
                        start: new Date()
                    },
                    subject: {
                        identifier: '',
                        type: product.subject_types[0].code
                    }
                });
            });
            setFormState({ ...formState, initialized: true, coverageFormData: coverageFormData });
        }
    });

    return (
        <div>
            <PanelHeader title={`Add coverages`} >
                <Tooltip content="Back to policy" relationship="label">
                    <Button onClick={() => navigate('/policies/' + policyId)} aria-label="Back to policy" icon={<ArrowLeftRegular />} />
                </Tooltip>
            </PanelHeader>
            <MessageBar
                layout="singleline"
                intent="info">
                <MessageBarBody>
                    Process of creation of new coverages is executed asynchronously, because of that UI may not reflect created data instantly.
                </MessageBarBody>
            </MessageBar>
            {isError && formState.errors.map((error, index) => <div key={index} className={styles.errorCard}><ErrorBar message={error} /></div>)}

            {isLoadingBundle || isLoadingPolicy && <Spinner label="Loading..." />}
            {policyLoadingError && <ErrorBar message='Something went wrong during policy load. Please refresh page or contact administrator' />}
            {bundleLoadingError && <ErrorBar message='Something went wrong during concept load. Please refresh page or contact administrator' />}

            {formState.initialized &&
                <CoverageForm bundle={bundle!} onSubmit={onSubmit} initialState={formState.coverageFormData} isSubmitting={formState.isSubmitting} isUpdate={false} />
            }
        </div >
    );
}

export default AddCoverage;